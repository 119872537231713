import { VALUABLE_LINKS } from '/@/config/app.config'
import { LanguageKey } from '/@/language'
import { RouteName, CategorySlug } from '/@/app/router'
import { getPageRoute, getCategoryFlowRoute } from '/@/transforms/route'
import { getTargetCDNURL } from '/@/transforms/url'

export interface MenuItem {
  id: string
  route?: string
  url?: string
  i18nKey: LanguageKey
  icon?: string
  imageIcon?: string
  divider?: boolean
  newWindow?: boolean
  disabledUppercase?: boolean
  hot?: boolean
  color?: string
}

export const menus: Array<MenuItem> = [
  {
    id: RouteName.Home,
    route: '/home',
    icon: 'icon-home',
    i18nKey: LanguageKey.PAGE_HOME
  },
  {
    id: CategorySlug.INSIGHT,
    route: getCategoryFlowRoute(CategorySlug.INSIGHT),
    icon: 'icon-insight code',
    i18nKey: LanguageKey.CATEGORY_INSIGHT
  },
  {
    id: CategorySlug.Code,
    route: getCategoryFlowRoute(CategorySlug.Code),
    icon: 'icon-code',
    i18nKey: LanguageKey.CATEGORY_CODE
  },
  {
    id: CategorySlug.FRONTEND,
    route: getCategoryFlowRoute(CategorySlug.FRONTEND),
    icon: 'icon-code',
    i18nKey: LanguageKey.CATEGORY_FRONTEND
  },
  {
    id: CategorySlug.JS,
    route: getCategoryFlowRoute(CategorySlug.JS),
    icon: 'icon-code',
    i18nKey: LanguageKey.CATEGORY_JS
  },
  {
    id: CategorySlug.VUE,
    route: getCategoryFlowRoute(CategorySlug.VUE),
    icon: 'icon-code',
    i18nKey: LanguageKey.CATEGORY_VUE
  },
  {
    id: 'github',
    url: VALUABLE_LINKS.GITHUB,
    icon: 'icon-github',
    i18nKey: LanguageKey.PAGE_GITHUB,
    newWindow: true
  },
  {
    id: CategorySlug.FAQ,
    route: getCategoryFlowRoute(CategorySlug.FAQ),
    icon: 'icon-insight',
    i18nKey: LanguageKey.CATEGORY_FAQ
  },
  {
    id: CategorySlug.AI,
    route: getCategoryFlowRoute(CategorySlug.AI),
    icon: 'icon-swordsman',
    i18nKey: LanguageKey.CATEGORY_AI,
    // color: '#00a8cc',
    color: '#62b873',
    hot: true
  },
  {
    id: CategorySlug.OTHER,
    route: getCategoryFlowRoute(CategorySlug.OTHER),
    icon: 'icon-swordsman',
    i18nKey: LanguageKey.CATEGORY_OTHER
  },
  {
    id: RouteName.Guestbook,
    route: getPageRoute(RouteName.Guestbook),
    i18nKey: LanguageKey.PAGE_GUESTBOOK,
    icon: 'icon-comment'
  },
  // {
  //   id: RouteName.App,
  //   route: getPageRoute(RouteName.App),
  //   imageIcon: getTargetCDNURL('/images/page-app/logo.png'),
  //   i18nKey: LanguageKey.PAGE_APP,
  //   divider: true
  // },
  // {
  //   id: 'nft',
  //   route: getPageRoute(RouteName.Nft),
  //   imageIcon: getTargetCDNURL('/images/third-party/opensea.svg'),
  //   i18nKey: LanguageKey.PAGE_NFT,
  //   disabledUppercase: true,
  //   hot: true,
  //   divider: true
  // },
  {
    id: 'nft',
    route: getPageRoute(RouteName.Nft),
    imageIcon: getTargetCDNURL('/images/third-party/opensea.svg'),
    // icon: 'icon-lens',
    i18nKey: LanguageKey.PAGE_NFT,
    disabledUppercase: true,
    hot: true
  },
  {
    id: RouteName.Lens,
    route: getPageRoute(RouteName.Lens),
    icon: 'icon-lens',
    i18nKey: LanguageKey.PAGE_LENS,
    color: '#f0ae71',
    hot: true
  },
  {
    id: RouteName.About,
    route: getPageRoute(RouteName.About),
    icon: 'icon-lens',
    i18nKey: LanguageKey.PAGE_ABOUT,
    color: '#00a8cc'
  }
]
