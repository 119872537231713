import { ref, onMounted, onBeforeUnmount, provide, inject } from 'vue'

type useTabVisibilityHookOptionTyoe = {
  onVisible: () => void
  onHidden: () => void
}

export const useTabVisibilityHook = (ctx?: useTabVisibilityHookOptionTyoe) => {
  const isTabVisible = ref(true)

  function handleVisibilityChange() {
    isTabVisible.value = document.visibilityState === 'visible'
    if (isTabVisible.value) {
      ctx?.onVisible?.()
    } else {
      ctx?.onHidden?.()
    }
  }

  onMounted(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange)
  })

  onBeforeUnmount(() => {
    document.removeEventListener('visibilitychange', handleVisibilityChange)
  })

  provide('isTabVisible', isTabVisible)

  return {
    isTabVisible: inject('isTabVisible', ref(true))
  }
}
