<template>
  <li class="flower-item" :style="flowerStyles" :class="{ playing: stateFlower.playing }">
    <!-- <webfont bolder>{{ options.text }}</webfont> -->
    {{ options.text }}
  </li>
</template>

<script lang="ts">
  import { defineComponent, computed, onMounted, onBeforeUnmount } from 'vue'
  import { useWallflower } from '/@/components/widget/wallflower/wallflower'
  import { randomArrayIndex } from '/@/utils/random'
  import { FLOWER_COLORS } from '/@/config/flower.config'

  enum Event {
    Ended = 'ended'
  }

  export default defineComponent({
    name: 'WallFlowerItem',
    props: {
      options: {
        type: Object,
        required: true
      },
      zIndex: {
        type: Number,
        required: true
      },
      stateAutoClick: {
        type: Object,
        required: false
      }
    },
    emits: [Event.Ended],
    setup(props, context) {
      const { stateFlower, handleAnimationStart } = useWallflower()
      const randomColor = randomArrayIndex(Object.values(FLOWER_COLORS))
      const flowerStyles = computed(() => ({
        top: props.options.y + 'px',
        left: props.options.x + 'px',
        color: randomColor,
        'z-index': 99999 + props.zIndex
      }))
      const toHandleAnimationEnd = () => {
        context.emit(Event.Ended, props.options.id)
      }

      onMounted(() => {
        handleAnimationStart({
          stateFlower,
          stateAutoClick: props.stateAutoClick,
          handleAnimationEnd: toHandleAnimationEnd
        })
      })
      onBeforeUnmount(() => {
        if (stateFlower.timer) {
          clearTimeout(stateFlower.timer)
          stateFlower.timer = null
        }
      })

      return {
        stateFlower,
        flowerStyles
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .flower-item {
    opacity: 0;
    position: fixed;
    display: block;
    transform: translate3d(-50%, -50%, 0);
    transition: opacity 0.5s;
    // font-family: 'Jinling', 'Sakura';
    // font-size: $font-size-h3;
    font-size: 13px;
    font-weight: 600;
    white-space: nowrap;

    @keyframes flower-playing {
      0% {
        transform: translate3d(-50%, 200%, 0);
        opacity: 0;
      }
      10% {
        transform: translate3d(-50%, -200%, 0);
        opacity: 1;
      }
      100% {
        transform: translate3d(-50%, -3000%, 0);
        opacity: 0;
      }
    }
    &.playing {
      opacity: 0;
      animation: flower-playing 10s ease 0s forwards;
    }
  }
</style>
