<template>
  <div v-cloak class="app-root">
    <client-only>
      <progress-bar :spin="!isMobile" />
      <emoji-rain />
      <popup-root />
    </client-only>
    <captured>
      <responsive>
        <template #desktop><desktop-layout /></template>
        <template #mobile><mobile-main /></template>
      </responsive>
    </captured>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { useEnhancer } from '/@/app/enhancer'
  import EmojiRain from '/@/components/widget/emoji-rain.vue'
  import Captured from '/@/components/root/captured.vue'
  import DesktopLayout from '/@/components/layout/desktop/layout.vue'
  import MobileMain from '/@/components/layout/mobile/main.vue'

  export default defineComponent({
    name: 'App',
    components: {
      EmojiRain,
      Captured,
      DesktopLayout,
      MobileMain
    },
    setup() {
      const { isMobile } = useEnhancer()

      return { isMobile }
    }
  })
</script>

<style lang="scss">
  @import 'src/styles/variables.scss';

  .app-root {
    height: 100%;

    &[v-cloak] {
      color: transparent;
      -webkit-text-fill-color: transparent;
    }

    #popup {
      .mask {
        @include responsive-scale-factor;
        // // zoom: var(--scale-factor);
        // transform: scale(var(--scale-factor));
        transform: translateZ(0) scale(var(--scale-factor), var(--scale-factor));
        transform-origin: top left;
        width: calc(100vw * (1 / var(--scale-factor))) !important;
        height: calc(100vh * (1 / var(--scale-factor))) !important;
        will-change: transform;
        -webkit-font-smoothing: antialiased;
      }
    }
  }
</style>
