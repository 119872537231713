<template>
  <header
    :id="HEADER_ELEMENT_ID"
    class="header"
    :class="[{ 'enable-nav': isEnabledHeadNav }, { dark: isDarkTheme }]"
  >
    <div class="header-container container">
      <div class="header-header">
        <!-- <uimage cdn src="/images/logo.svg" class="header-logo" :alt="META.title" /> -->
        <!-- <uimage cdn src="/images/69541590_p0.jpg" class="header-logo" :alt="META.title" /> -->
        <div class="header-slogan1">
          <i18n :k="LanguageKey.APP_SLOGAN1" />
        </div>
        <div class="header-slogan2">
          <i18n :k="LanguageKey.APP_SLOGAN2" />
        </div>
        <router-link
          to="/"
          class="header-link"
          :title="META.title"
          @mousedown="handleRootNavEvent"
        />
        <saying />
      </div>
      <div class="toolbox">
        <button class="button menu" v-if="isEnabledHeadNav">
          <i class="iconfont icon-top-menu"></i>
        </button>
        <button
          v-if="route.name === RouteName.Index && isLoadedBackgroundVideo"
          class="button menu"
          @click.stop="toggleMutedVideoPlayer"
        >
          <i
            v-if="backgroundVideoPlayer?.stateVideoPlayer.player?.muted"
            class="iconfont icon-music-muted"
          ></i>
          <i v-else class="iconfont icon-music-unmuted"></i>
        </button>
        <button
          v-if="route.name !== RouteName.Index"
          class="button menu"
          @click.stop="music?.togglePlay()"
          key="music"
        >
          <i v-if="music?.state.playing" class="iconfont icon-music-pause"></i>
          <i v-else class="iconfont icon-music-play"></i>
        </button>
        <button class="button language" title="Switch language" @click="tooggleLanguage">
          {{ language || '-' }}
        </button>
        <button class="button theme" :class="theme" @click="toggleTheme">
          <i class="iconfont" :class="themeIcon"></i>
        </button>
      </div>
    </div>
    <div class="header-nav">
      <nav class="nav-list container">
        <template v-for="(menu, index) in menus" :key="menu.id">
          <span v-if="index > 0" class="divider"></span>
          <ulink class="item" :class="menu.id" :to="menu.route" :href="menu.url">
            <uimage v-if="menu.imageIcon" class="image-icon" :src="menu.imageIcon" />
            <i v-else-if="menu.icon" class="font-icon iconfont" :class="menu.icon"></i>
            <webfont class="text" bolder :uppercase="!menu.disabledUppercase">
              <i18n :k="menu.i18nKey" />
            </webfont>
            <span v-if="menu.hot" class="superscript">
              <i class="iconfont icon-hot-fill"></i>
            </span>
            <span v-if="menu.newWindow" class="new-window">
              <i class="iconfont icon-new-window-s"></i>
            </span>
          </ulink>
        </template>
      </nav>
    </div>
  </header>
</template>

<script lang="ts">
  import { defineComponent, ref, computed, watch, inject } from 'vue'
  import { useEnhancer } from '/@/app/enhancer'
  import { LanguageKey } from '/@/language'
  import { Language } from '/@/language'
  import { RouteName } from '/@/app/router'
  import { Theme } from '/@/composables/theme'
  import { HEADER_ELEMENT_ID } from '/@/constants/anchor'
  import { GAEventCategories } from '/@/constants/gtag'
  import { META } from '/@/config/app.config'
  import { menus } from '/@/components/layout/desktop/menu'
  import { useBackgroundVideo } from '/@/components/widget/background-video/background-video'
  import { useMusic } from '/@/composables/music'
  import Saying from '/@/components/widget/saying/saying.vue'

  export default defineComponent({
    name: 'DesktopHeader',
    components: {
      Saying
    },
    setup() {
      const { indexBackgroundVideoRef: injectIndexBackgroundVideoRef } = inject(
        'provideIndexPage'
      ) as any
      const { i18n, gtag, theme, globalState, isEnLang, isDarkTheme, route } = useEnhancer()
      const music = useMusic()

      let backgroundVideoPlayer = ref(
        useBackgroundVideo({
          ctxStateVideoPlayer: {
            player: injectIndexBackgroundVideoRef.value
          }
        })
      )
      let isLoadedBackgroundVideo = ref(false)

      watch(
        () => injectIndexBackgroundVideoRef.value,
        (newVal) => {
          if (newVal) {
            setTimeout(() => {
              backgroundVideoPlayer.value = useBackgroundVideo({
                ctxStateVideoPlayer: {
                  player: injectIndexBackgroundVideoRef.value
                }
              })
              isLoadedBackgroundVideo.value = true
            }, 1500)
          }
        },
        {
          deep: true
        }
      )
      // enable header nav bar when full page layout
      const isEnabledHeadNav = computed(
        () => !globalState.layoutColumn.value.isNormal || route.name === RouteName.Index
      )

      const themeValue = theme.theme
      const themeIcon = computed(() => {
        const themeIconMap = {
          [Theme.Light]: 'icon-sun',
          [Theme.Dark]: 'icon-moon'
        }
        return themeIconMap[themeValue.value]
      })

      const toggleMutedVideoPlayer = () => {
        backgroundVideoPlayer.value?.toggleMutedVideoPlayer()
        backgroundVideoPlayer.value = useBackgroundVideo({
          ctxStateVideoPlayer: {
            player: injectIndexBackgroundVideoRef.value
          }
        })
      }

      const toggleTheme = () => {
        theme.toggle()
        gtag?.event('switch_theme', {
          event_category: GAEventCategories.Widget,
          event_label: theme.theme.value
        })
      }

      const tooggleLanguage = () => {
        i18n.toggle()
        gtag?.event('switch_language', {
          event_category: GAEventCategories.Widget,
          event_label: i18n.l.value?.name
        })
      }

      const handleRootNavEvent = () => {
        gtag?.event('root_header_home_nav', {
          event_category: GAEventCategories.Universal
        })
        if (route.name === RouteName.Index) {
          backgroundVideoPlayer.value?.replayVideoPlayer()
        } else {
          backgroundVideoPlayer.value?.replayVideoPlayer({ delay: 1000 })
        }
      }

      return {
        menus,
        META,
        HEADER_ELEMENT_ID,
        LanguageKey,
        Language,
        isEnLang,
        isDarkTheme,
        isEnabledHeadNav,
        handleRootNavEvent,
        t: i18n.t,
        language: i18n.language,
        tooggleLanguage,
        theme: themeValue,
        themeIcon,
        toggleTheme,
        route,
        RouteName,
        music,
        backgroundVideoPlayer,
        toggleMutedVideoPlayer,
        isLoadedBackgroundVideo
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  .fade-enter-to,
  .fade-leave-from {
    opacity: 1;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 5s ease;
  }

  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $header-height;
    z-index: $z-index-header;
    background-color: $module-bg;
    border-bottom: 1px solid $module-bg-darker-2;
    @include backdrop-blur(5px);
    &.dark {
      .header-header {
        .header-saying {
          color: $gray-light !important;
        }
      }
      .header-nav {
        // background-color: rgba($cyan-light, 0.3);
        background-color: rgba($grey, 0.8);
      }
    }

    &.enable-nav:hover {
      .header-nav {
        @include visible();
      }
    }

    .header-container {
      height: 100%;
      display: flex;
      justify-content: space-between;

      .header-header {
        height: 100%;
        display: flex;
        position: relative;
        align-items: center;
        // padding-left: $sm-gap;
        // width: 29em;
        overflow: hidden;

        @keyframes logo-blink {
          0% {
            mask-position: -30%;
          }
          100% {
            mask-position: 666%;
          }
        }

        .header-logo {
          // width: 11rem;
          width: 120px;
          margin-right: $gap * 4;
          opacity: 0.5;
          filter: $theme-logo-rotate;
          .logo-st {
            fill: $primary;
          }
        }

        .header-slogan1 {
          color: $rosy-dark;
          font-family: 'PingFang-Bold';
          // font-size: $font-size-h5;
          font-size: 22px;
          font-weight: 600;
          letter-spacing: 4px;
        }

        .header-slogan2 {
          color: $gray;
          font-family: 'PingFang-Bold';
          // font-size: $font-size-h5;
          font-size: 22px;
          font-weight: 600;
          letter-spacing: 4px;
        }

        .header-link {
          position: absolute;
          width: 100%;
          height: 100%;
        }
      }

      .toolbox {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        @include visibility-transition();
        // opacity: 0.6;
        // &:hover {
        //   opacity: 1;
        // }

        .button {
          position: relative;
          display: block;
          text-transform: uppercase;
          margin: 0 $gap;
          width: 2rem;
          height: 2rem;
          opacity: 0.6;
          &.menu {
            // cursor: none;
            &:hover {
              opacity: 1;
            }
          }
          &.language {
            font-family: 'PingFang-Bold';
            font-weight: bold;
            &:hover {
              opacity: 1;
            }
          }
          &.theme {
            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }

    .header-nav {
      width: 100%;
      height: 4rem;
      // background-color: $primary-lighter;
      background-color: rgba($cyan-light, 0.8);
      @include hidden();
      @include visibility-transition();

      .nav-list {
        height: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        .divider {
          height: 6px;
          width: 1px;
          background-color: $module-bg-translucent;
        }

        a {
          color: rgba(#000, 0.74);

          &:hover,
          &:focus {
            color: rgba(#000, 0.86);
          }
        }

        .item {
          position: relative;
          display: inline-flex;
          align-items: center;
          // color: $text-reversal;
          // opacity: 0.7;
          opacity: 0.9;
          @include visibility-transition();
          &:hover {
            opacity: 1;
          }
          &.link-active {
            .text {
              padding-top: 4px;
              padding-bottom: 2px;
              border-bottom: 2px solid;
            }
          }

          .font-icon {
            margin-right: $sm-gap;
          }

          .image-icon {
            width: 1em;
            height: 1em;
            margin-right: $sm-gap;
            border-radius: $xs-radius;
          }

          .superscript {
            margin-top: 1px;
            margin-left: $xs-gap;
          }

          .new-window {
            margin-left: $xs-gap;
            margin-right: -$xs-gap;
            // margin-top: -$sm-gap;
            font-size: $font-size-small - 3;
            opacity: 0.8;
          }
        }

        .nft {
          filter: grayscale(1);
        }
      }
    }
  }
</style>
