/**
 * @file App config
 * @module config.app
 * @author rosygray <https://github.com/rosygray>
 */

export const DEFAULT_DELAY = 468
export const LONG_ARTICLE_THRESHOLD = 16688

export const META = Object.freeze({
  title: 'rosygray',
  zh_sub_title: 'ROSYGRAY',
  en_sub_title: 'ROSYGRAY',
  zh_biography: '人间归离复归离，借一浮生逃浮生',
  en_biography: 'Either write something worth reading or do something worth writing.',
  url: 'https://blog.rosygray.com',
  domain: 'blog.rosygray.com',
  author: 'rosygray',
  primary: '#0088f5'
})

export const GEO_INFO = Object.freeze({
  // zh_title: '星移斗转，沧海桑田，烟火人间依旧',
  zh_title: '物换星移几度秋',
  en_title: 'UTC +08:00',
  // coordinates: [103.830391822121, 1.340863]
  coordinates: [113.8642522, 22.6594894]
})

export const MAPBOX_CONFIG = Object.freeze({
  // readonly token
  TOKEN:
    'pk.eyJ1Ijoicm9zeWdyYXkiLCJhIjoiY2xoaHhiYWxlMDJmbjNybXh5MThqNW9vaSJ9.Zrxi1bSQaqXQ9mj5t3katw',
  STYLE_LIGHT: 'mapbox://styles/rosygray/clhhxo58k01at01qt3kqsgexr',
  STYLE_DARK: 'mapbox://styles/rosygray/clhhy3fdn01f301pr8bvj20xj',
  ZOOM: 11.337439,
  CENTER: [113.8642522, 22.6594894]
})

export const IDENTITIES = Object.freeze({
  // GOOGLE_ANALYTICS_TRACKING_ID: 'UA-263830056-1',
  GOOGLE_ANALYTICS_TRACKING_ID: 'G-M9MS413TMC',
  GOOGLE_ADSENSE_PUBLISHER_ID: 'ca-pub-9162153565043669',
  YOUTUBE_CHANNEL_ID: 'UCEwlVpiLk8_EKS6MPD4hdBw',
  MUSIC_163_BGM_ALBUM_ID: '8377312551',
  DOUBAN_USER_ID: '270538760',
  GITHUB_USER_NAME: 'rosygray',
  TWITTER_USER_NAME: 'since19950531',
  INSTAGRAM_USERNAME: 'since19950531',
  INSTAGRAM_FB_ID: '100010809902772',
  BTC_ADDRESS: 'bc1qf8dzfzs2p7v0gxp6gd5aqgakhpn2ylpnu04rjw',
  ETH_ADDRESS: '0xC60e25D4A79f6D270378fc8A2877Ba359921eDDf',
  OPENSEA_ETH: '0xC60e25D4A79f6D270378fc8A2877Ba359921eDDf'
})

export const VALUABLE_LINKS = Object.freeze({
  RSS: '/rss.xml',
  SITE_MAP: '/sitemap.xml',
  UPTIME_STATUS: 'https://stats.uptimerobot.com/WAWEnF6nmR',
  NPM_HOMEPAGE: 'https://www.npmjs.com/~rosygray',
  PAYPAL: 'https://paypal.me/rosygray',
  GITHUB_SPONSORS: `https://github.com/sponsors/${IDENTITIES.GITHUB_USER_NAME}`,
  GITHUB: `https://github.com/${IDENTITIES.GITHUB_USER_NAME}`,
  GITHUB_ROSYGRAY_BLOG: 'https://github.com/rosygray/rosygray.com-blog',
  GITHUB_ROSYGRAY_NODEPRESS: 'https://github.com/rosygray/rosygray.com-nodepress',
  GITHUB_ROSYGRAY_BLOG_NATIVE: 'https://github.com/rosygray/rosygray.com-blog',
  GITHUB_BLOG_STAR_LIST: 'https://github.com/stars/rosygray/lists/rosygray.com-blog',
  APP_APK_FILE:
    'https://raw.githubusercontent.com/surmon-china/surmon.me.native/master/dist/android/surmon.me.apk',
  MARKDOWN: 'https://daringfireball.net/projects/markdown/',
  GOOGLE_MY_MAP: `https://www.google.com/maps/d/embed?mid=1rs2ScjJG-pgalzBK_SY4k-ae9uCasjk&ehbc=2E312F`,
  GOOGLE_MY_MAP_KML: `https://www.google.com/maps/d/kml?forcekml=1&mid=1rs2ScjJG-pgalzBK_SY4k-ae9uCasjk`,
  DISCORD_GROUP: 'https://discord.gg/6vGrvtKazh',
  TELEGRAM_GROUP: 'https://t.me/+Z2wsxogVbYM2ZmE1',
  MUSIC_163: `https://music.163.com/#/playlist?id=${IDENTITIES.MUSIC_163_BGM_ALBUM_ID}`,
  YOUTUBE_CHANNEL: `https://www.youtube.com/channel/${IDENTITIES.YOUTUBE_CHANNEL_ID}`,
  TELEGRAM: 'https://t.me/rosygray',
  OPENSEA: 'https://opensea.io/rosygray',
  DOUBAN: `https://www.douban.com/people/${IDENTITIES.DOUBAN_USER_ID}`,
  DOUBAN_MOVIE: `https://m.douban.com/people/${IDENTITIES.DOUBAN_USER_ID}/movie_charts`,
  QUORA: 'https://www.quora.com/profile/Phosme',
  LINKEDIN: 'https://www.linkedin.com/in/rosygray',
  INSTAGRAM: `https://www.instagram.com/${IDENTITIES.INSTAGRAM_USERNAME}`,
  TWITTER: `https://twitter.com/${IDENTITIES.TWITTER_USER_NAME}`
})
