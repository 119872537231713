<template>
  <span
    class="webfont"
    :class="[
      bolder ? 'bolder' : 'medium',
      isZhLang ? 'zh' : 'en',
      { boldZh },
      { boldEn },
      { uppercase }
    ]"
  >
    <slot></slot>
  </span>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { useEnhancer } from '/@/app/enhancer'
  export default defineComponent({
    name: 'Webfont',
    props: {
      bolder: {
        type: Boolean,
        default: false
      },
      boldZh: {
        type: Boolean,
        default: false
      },
      boldEn: {
        type: Boolean,
        default: true
      },
      uppercase: {
        type: Boolean,
        default: false
      }
    },
    setup() {
      const { isZhLang } = useEnhancer()
      return {
        isZhLang
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .webfont {
    &.zh {
      font-weight: normal;
      /* HACK: for Chinese font */
      // margin-top: -1px;
      letter-spacing: 2px;

      &.medium {
        font-family: 'webfont-medium';
      }
      &.bolder {
        font-family: 'webfont-bolder';
        font-weight: bold;
      }
      &.boldZh {
        font-weight: bold;
      }
      &.nav,
      &.hotfill {
        font-family: $font-family-kanji;
        font-size: 14px;
        font-weight: bold;
      }
    }

    &.en {
      font-size: 95%;
      font-family: inherit;
      letter-spacing: 2px;
      font-family: 'PingFang-Bold';
      &.boldEn {
        font-weight: bold;
      }
      &.uppercase {
        text-transform: uppercase;
      }
      &.nav,
      &.hotfill {
        font-family: 'Sakura';
        font-size: 12px;
        font-weight: bold;
      }
    }
  }
</style>
