import { reactive, onMounted, onBeforeUnmount, nextTick } from 'vue'
import { randomRange, randomArrayIndex } from '/@/utils/random'

export interface stateAutoClick {
  mode: number
  delay: number
  getAreaX?: any
  getAreaY?: any
}

export interface IFlower {
  id: number
  x: number
  y: number
  text: string
}

export const useWallflower = () => {
  const stateFlower = reactive({
    timer: null as number | null,
    playing: false
  })
  const stateGarden = reactive({
    id: 0,
    flowers: [] as IFlower[],
    contentIndex: -1
  })
  const stateAutoClick = reactive({
    currentAutoClickMode: 0,
    lastActiveAutoClickMode: 0,
    isEnableAutoClick: false,
    autoClickParams: {
      mode: 0,
      delay: 1000,
      duration: 10000,
      getAreaX: () => {
        null
      },
      getAreaY: () => {
        null
      }
    } as stateAutoClick | undefined
  })

  const getScaleFactor = () => {
    const scaleElement = document.querySelector('.desktop-layout')
    const scaleFactor = scaleElement
      ? parseFloat(getComputedStyle(scaleElement).getPropertyValue('--scale-factor'))
      : 1
    return scaleFactor
  }

  const handleGardenClick = (
    event: MouseEvent | any,
    ctx: {
      stateGarden
      stateAutoClick
      getFlowerTextArray
    }
  ) => {
    const { stateGarden, stateAutoClick, getFlowerTextArray } = ctx
    const flowerTextArray = getFlowerTextArray()
    stateGarden.contentIndex++
    if (stateGarden.contentIndex >= flowerTextArray.length) {
      stateGarden.contentIndex = 0
    }

    stateGarden.flowers.push({
      id: ++stateGarden.id,
      x:
        event.x / getScaleFactor() ||
        event.clientX / getScaleFactor() ||
        stateAutoClick?.autoClickParams?.getAreaX(),
      y:
        event.y / getScaleFactor() ||
        event.clientY / getScaleFactor() ||
        stateAutoClick?.autoClickParams?.getAreaY(),
      // text: flowers[stateGarden.contentIndex]
      text: randomArrayIndex(flowerTextArray)
    } as IFlower)
  }

  const handleAnimationStart = (ctx: { stateFlower; stateAutoClick; handleAnimationEnd }) => {
    const { stateFlower, stateAutoClick, handleAnimationEnd } = ctx
    nextTick(() => {
      // start animation
      setTimeout(() => {
        stateFlower.playing = true
      })

      // End animation
      stateFlower.timer = window.setTimeout(() => {
        stateFlower.playing = false
        nextTick(() => {
          handleAnimationEnd()
        })
        // }, stateFlower.delay * 10000)
      }, stateAutoClick.autoClickParams.duration)
    })
  }

  const handleAnimationEnd = (id: number, ctx: { stateGarden }) => {
    const { stateGarden } = ctx
    const targetIndex = stateGarden.flowers.findIndex((flower) => flower.id === id)
    if (targetIndex > -1) {
      stateGarden.flowers.splice(targetIndex, 1)
    }
  }

  const initStateAutoClick = (ctx: { mode: number; delayInit: number }) => {
    setTimeout(() => {
      stateAutoClick.autoClickParams = autoClickSwitch(ctx.mode)
    }, ctx.delayInit)
  }

  const autoClickSwitch = (mode) => {
    // stateAutoClick.currentAutoClickMode = mode
    if (mode === 0) {
      stateAutoClick.isEnableAutoClick = false
    } else if (stateAutoClick.currentAutoClickMode === mode) {
      stateAutoClick.isEnableAutoClick = !stateAutoClick.isEnableAutoClick
    } else {
      stateAutoClick.isEnableAutoClick = true
    }
    stateAutoClick.currentAutoClickMode = stateAutoClick.isEnableAutoClick ? mode : 0
    const autoClickAreaElement: any = document.getElementById('autoClickArea')
    const autoClickArea1Element: any = document.getElementById('autoClickArea1')
    const autoClickArea2Element: any = document.getElementById('autoClickArea2')
    const areaX_Mode1 = () => autoClickAreaElement?.offsetParent?.offsetLeft / 1.75
    const areaY_Mode1 = () =>
      autoClickArea1Element?.offsetParent?.offsetTop +
      autoClickArea1Element?.offsetParent?.offsetHeight
    const areaX_Mode2 = () =>
      randomRange(
        autoClickArea2Element?.offsetParent?.offsetLeft,
        autoClickAreaElement?.offsetLeft + autoClickAreaElement?.offsetParent?.offsetLeft
      )
    const areaY_Mode2 = () =>
      randomRange(
        autoClickAreaElement?.offsetTop,
        autoClickArea2Element?.offsetParent?.offsetTop +
          autoClickArea2Element?.offsetParent?.offsetHeight
      )

    const stateAutoClickMapper = new Map([
      [
        0,
        {
          mode: 0,
          delay: 1000,
          duration: 10000,
          getAreaX: () => {
            null
          },
          getAreaY: () => {
            null
          }
        }
      ],
      [
        1,
        {
          mode: 1,
          delay: 1000,
          duration: 10000,
          getAreaX: areaX_Mode1,
          getAreaY: areaY_Mode1
        }
      ],
      [
        2,
        {
          mode: 2,
          delay: 200,
          duration: 10000,
          getAreaX: areaX_Mode2,
          getAreaY: areaY_Mode2
        }
      ]
    ])
    stateAutoClick.autoClickParams = stateAutoClickMapper.get(mode)
    return stateAutoClickMapper.get(mode)
  }

  let handleAutoClickAreaTimer: string | number | any = null
  const handleAutoClickArea = (stateAutoClick, handleClick: any) => {
    if (!stateAutoClick.isEnableAutoClick) {
      clearInterval(handleAutoClickAreaTimer)
      return
    }
    clearInterval(handleAutoClickAreaTimer)
    handleAutoClickAreaTimer = setInterval(() => {
      handleClick({})
    }, stateAutoClick.autoClickParams.delay)
  }
  const resumeAutoClickTimer = () => {
    autoClickSwitch(stateAutoClick.lastActiveAutoClickMode)
  }
  const resetAutoClickTimer = () => {
    stateAutoClick.lastActiveAutoClickMode = stateAutoClick.currentAutoClickMode
    autoClickSwitch(0)
  }

  onBeforeUnmount(() => {
    clearInterval(handleAutoClickAreaTimer)
  })

  return {
    stateFlower,
    stateGarden,
    stateAutoClick,

    handleGardenClick,
    handleAnimationStart,
    handleAnimationEnd,

    autoClickSwitch,
    initStateAutoClick,
    handleAutoClickAreaTimer,
    handleAutoClickArea,
    resumeAutoClickTimer,
    resetAutoClickTimer
  }
}
