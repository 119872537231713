<template>
  <div v-if="loading" class="loading-wrapper">
    <div
      class="loading-box"
      :style="loadingStyle"
      style="animation-delay: 0s; animation-duration: 1300ms"
    ></div>
    <div
      class="loading-box"
      :style="loadingStyle"
      style="animation-delay: 0.1s; animation-duration: 1300ms"
    ></div>
    <div
      class="loading-box"
      :style="loadingStyle"
      style="animation-delay: 0.2s; animation-duration: 1300ms"
    ></div>
    <div
      class="loading-box"
      :style="loadingStyle"
      style="animation-delay: -0.1s; animation-duration: 1300ms"
    ></div>
    <div
      class="loading-box"
      :style="loadingStyle"
      style="animation-delay: 0s; animation-duration: 1300ms"
    ></div>
    <div
      class="loading-box"
      :style="loadingStyle"
      style="animation-delay: 0.1s; animation-duration: 1300ms"
    ></div>
    <div
      class="loading-box"
      :style="loadingStyle"
      style="animation-delay: -0.2s; animation-duration: 1300ms"
    ></div>
    <div
      class="loading-box"
      :style="loadingStyle"
      style="animation-delay: -0.1s; animation-duration: 1300ms"
    ></div>
    <div
      class="loading-box"
      :style="loadingStyle"
      style="animation-delay: 0s; animation-duration: 1300ms"
    ></div>
  </div>
</template>

<script>
  export default {
    name: 'Loading',
    props: {
      loading: {
        type: Boolean,
        default: true
      },
      color: {
        type: String,
        default: '#ccc'
      }
    },
    data() {
      return {
        loadingStyle: {
          backgroundColor: this.color
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .loading-wrapper {
    width: 38px;
    height: 38px;
    margin: auto;
    padding: 15vh 0;

    @keyframes loading-animation {
      0%,
      70%,
      to {
        transform: scaleZ(1);
      }

      35% {
        transform: scale3D(0, 0, 1);
      }
    }

    .loading-box {
      width: 12px;
      height: 12px;
      // background-color: #888;
      float: left;
      animation: loading-animation infinite ease-in-out;
    }
  }
</style>
