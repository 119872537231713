<template>
  <div id="desktop-main" class="desktop-main">
    <background />
    <wallflower v-if="isEnabledWallflower" :state-auto-click="stateAutoClick" />
    <div v-show="isEnabledAutoWallflower" class="auto-click-container">
      <div
        id="autoClickArea1"
        :class="{ playing: stateAutoClick.currentAutoClickMode === 1 }"
        @click.stop="autoClickSwitch(1)"
      ></div>
      <div
        id="autoClickArea2"
        :class="{ playing: stateAutoClick.currentAutoClickMode === 2 }"
        @click.stop="autoClickSwitch(2)"
      ></div>
      <div
        id="autoClickArea0"
        :class="{ playing: stateAutoClick.currentAutoClickMode === 0 }"
        @click.stop="autoClickSwitch(0)"
      ></div>
    </div>
    <client-only>
      <popup :visible="switcher.sponsor" @close="handleSponsorModalClose">
        <div class="sponsor-modal">
          <div class="sponsor">
            <sponsor-tabs class="tabs" :state="sponsorState" :hide-title="true" />
            <sponsor-provider class="provider" :state="sponsorState" :max-sponsors="9" />
          </div>
        </div>
      </popup>
      <popup
        :visible="switcher.feedback"
        :mask-close="false"
        :scroll-close="false"
        @close="handleFeedbackModalClose"
      >
        <feedback @close="handleFeedbackModalClose" />
      </popup>
      <popup :visible="switcher.statement" :scroll-close="false" @close="handleStatementModalClose">
        <statement />
      </popup>
    </client-only>
    <template v-if="!layoutColumn.isFull">
      <share class="main-share" />
      <wallpaper />
      <toolbox />
      <client-only>
        <music-player-handle />
      </client-only>
    </template>
    <!-- <header-view v-if="!route.meta.isIndex" /> -->
    <main
      :id="MAIN_ELEMENT_ID"
      class="main-container"
      :class="{ 'full-page': layoutColumn.isFull }"
    >
      <!-- MARK: keep order > long content > flicker -->
      <nav-view class="nav-view" v-if="layoutColumn.isNormal" />
      <aside-view class="aside-view" v-if="layoutColumn.isNormal" />
      <div
        :id="MAIN_CONTENT_ELEMENT_ID"
        class="main-view"
        :class="{
          'layout-normal': layoutColumn.isNormal,
          'layout-wide': layoutColumn.isWide,
          'layout-full': layoutColumn.isFull
        }"
      >
        <!-- unuse suspense -> async route component -> can't extract style to css file -->
        <router-view v-slot="{ Component, route }">
          <div class="router-view">
            <transition name="page" mode="out-in" @before-enter="handlePageTransitionDone">
              <suspense>
                <keep-alive :include="keepAliveIncludeList">
                  <component :is="Component" :key="route.name" />
                </keep-alive>
              </suspense>
            </transition>
          </div>
        </router-view>
      </div>
    </main>
    <!-- <footer-view class="footer-view" /> -->
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, onMounted, computed, watch, inject, Ref } from 'vue'
  import { MAIN_ELEMENT_ID, MAIN_CONTENT_ELEMENT_ID } from '/@/constants/anchor'
  import { LanguageKey } from '/@/language'
  import { useEnhancer } from '/@/app/enhancer'
  import { LayoutColumn } from '/@/app/state'
  import { RouteName } from '/@/app/router'
  import { useMusic } from '/@/composables/music'
  import { useWallpaperStore } from '/@/stores/wallpaper'
  import { getLayoutByRouteMeta } from '/@/transforms/layout'
  import { useWallflower } from '/@/components/widget/wallflower/wallflower'
  import MusicPlayerHandle from '/@/components/widget/music-player/handle.vue'
  import Wallflower from '/@/components/widget/wallflower/garden.vue'
  import Wallpaper from '/@/components/widget/wallpaper/switcher.vue'
  import Background from '/@/components/widget/background.vue'
  import { useSponsorState } from '/@/components/widget/sponsor/state'
  import SponsorTabs from '/@/components/widget/sponsor/tabs.vue'
  import SponsorProvider from '/@/components/widget/sponsor/provider.vue'
  import Share from '/@/components/widget/share.vue'
  import Toolbox from '/@/components/widget/toolbox.vue'
  import Feedback from '/@/components/widget/feedback.vue'
  import Statement from '/@/components/widget/statement.vue'
  import NavView from './nav.vue'
  import AsideView from './aside/index.vue'
  import HeaderView from './header.vue'
  // import FooterView from './footer.vue'

  export default defineComponent({
    name: 'DesktopMain',
    components: {
      Share,
      Feedback,
      Wallpaper,
      Statement,
      SponsorTabs,
      SponsorProvider,
      MusicPlayerHandle,
      Toolbox,
      Wallflower,
      Background,
      HeaderView,
      // FooterView,
      AsideView,
      NavView
    },
    setup() {
      const sponsorState = useSponsorState()
      const wallpaperStore = useWallpaperStore()
      const {
        initStateAutoClick,
        stateAutoClick,
        autoClickSwitch,
        resumeAutoClickTimer,
        resetAutoClickTimer
      } = useWallflower()
      const { route, router, globalState } = useEnhancer()
      const music = useMusic()
      // const isEnabledHeadNav = computed(() => !globalState.layoutColumn.isNormal)
      const isEnabledWallflower = computed(() => route.meta.layout !== LayoutColumn.Full)
      const isEnabledAutoWallflower = computed(
        () => route.meta.layout !== LayoutColumn.Full && route.name !== RouteName.Index
      )
      const keepAliveIncludeList = computed(() => {
        const matchedRouteList = router.options.routes.filter(
          (item) => item.meta && item.meta.keepAlive
        )
        const matchedRouteComponetList = matchedRouteList.map(
          (item) => item?.components?.default?.name || ''
        )
        return matchedRouteComponetList || []
      })

      const handlePageTransitionDone = () => {
        globalState.setLayoutColumn(getLayoutByRouteMeta(route.meta))
      }
      const handleSponsorModalClose = () => {
        globalState.toggleSwitcher('sponsor', false)
      }
      const handleFeedbackModalClose = () => {
        globalState.toggleSwitcher('feedback', false)
      }
      const handleStatementModalClose = () => {
        globalState.toggleSwitcher('statement', false)
      }
      // let music: any = ref(null)

      // watch(isEnabledWallflower, (newVal, oldVal) => {
      //   if (newVal && newVal !== oldVal) {
      //     initStateAutoClick({
      //       mode: 1,
      //       delayInit: 5000
      //     })
      //   } else if (!newVal) {
      //     autoClickSwitch(0)
      //   }
      // })

      const isTabVisible = inject('isTabVisible') as Ref<boolean>
      watch(isTabVisible, (newValue) => {
        if (newValue) {
          resumeAutoClickTimer()
        } else {
          resetAutoClickTimer()
        }
      })

      watch(
        () => route,
        (newVal) => {
          // globalState.setLayoutColumn(getLayoutByRouteMeta(route.meta))
          if (newVal.meta.layout === LayoutColumn.Full) {
            if (route.name === RouteName.Index) {
              music?.pause()
            }
            resetAutoClickTimer()
          } else {
            if (!music.state?.isManualPause) {
              music?.play()
            }
          }
        },
        {
          deep: true
          // immediate: true
        }
      )

      onMounted(async () => {
        // bing wallpaper
        wallpaperStore.fetch()
        // music player
        await music.init()
        // const autoPlayMusic = (event) => {
        //   if (route.name === RouteName.Home) {
        //     if (!event.target.className.includes('icon-music-play')) {
        //       music.play()
        //     }
        //     document.removeEventListener('click', autoPlayMusic)
        //   }
        // }
        // document.addEventListener('click', autoPlayMusic)

        // if (isEnabledWallflower) {
        //   initStateAutoClick({
        //     mode: 1,
        //     delayInit: 3000
        //   })
        //   setTimeout(() => {
        //     autoClickSwitch(2)
        //   }, 30000)
        //   setTimeout(() => {
        //     autoClickSwitch(0)
        //   }, 60000)
        // }
      })

      return {
        route,
        LanguageKey,
        MAIN_ELEMENT_ID,
        MAIN_CONTENT_ELEMENT_ID,
        switcher: globalState.switcher,
        layoutColumn: globalState.layoutColumn,
        sponsorState,
        // isEnabledHeadNav,
        isEnabledWallflower,
        isEnabledAutoWallflower,
        keepAliveIncludeList,
        handlePageTransitionDone,
        handleSponsorModalClose,
        handleFeedbackModalClose,
        handleStatementModalClose,

        initStateAutoClick,
        stateAutoClick,
        autoClickSwitch
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .sponsor-modal {
    width: 68rem;
    height: 38rem;
    display: flex;
    flex-direction: column;
    background-color: $module-bg-lighter !important;

    .sponsor {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      height: 100%;

      .tabs {
        height: 12%;
        background-color: $module-bg-lighter;
        border-bottom: 1px solid $module-bg-darker-1;
      }

      .provider {
        // flex: 1;
        height: 88%;
      }
    }
  }

  .desktop-main {
    // height: calc(100% - $header-height - $lg-gap);
    min-height: calc(100% - $header-height - $footer-height - $lg-gap * 2);
    padding-top: $header-height + $lg-gap;
    // margin-top: $header-height;
    // padding-top: $header-height;

    @media screen and (max-width: 1200px) {
      #theme,
      #language,
      .main-share {
        display: none !important;
      }
    }

    .main-share {
      // position: fixed;
      position: absolute;
      top: 18%;
      left: 0;
      height: auto;
      max-width: 4rem;
      display: flex;
      flex-direction: column;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }

      ::v-deep(.share-ejector) {
        $height: 2.1em;
        width: 3rem;
        height: $height;
        line-height: $height;
        font-size: $font-size-h4;
        border-top-right-radius: $xs-radius;
        border-bottom-right-radius: $xs-radius;
        transition: width $transition-time-fast;
        &:hover {
          width: 120%;
        }
      }
    }

    .main-container {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: $container-width;
      // min-height: calc(100vh - $header-height - $footer-height - $lg-gap * 2);
      min-height: calc(100% - $header-height - $footer-height - $lg-gap * 2);
      // padding-top: $header-height;
      &.full-page {
        width: 100%;
      }

      .nav-view {
        order: 1;
        flex-shrink: 0;
        margin-right: $lg-gap;
      }

      .aside-view {
        order: 3;
        flex-shrink: 0;
        margin-left: $lg-gap;
      }

      .main-view {
        height: 100%;
        order: 2;
        flex-grow: 1;
        position: relative;
        overflow: hidden;
        transition: none;

        &:-moz-full-screen {
          overflow-y: auto;
        }

        &:-webkit-full-screen {
          overflow-y: auto;
        }

        &:fullscreen {
          overflow-y: auto;
        }

        &.layout-normal {
          /* flex-grow: 1; */
        }

        &.layout-wide {
          flex-grow: unset;
          width: 100%;
          margin: 0;
        }

        &.layout-full {
          flex-grow: unset;
          width: 100%;
          margin: -$lg-gap 0;
        }
      }

      .router-view {
        height: 100%;
      }
    }

    .footer-view {
      margin-top: $lg-gap;
      // margin-top: 1rem;
      .container {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .auto-click-container {
      position: fixed;
      left: 0;
      bottom: 10%;
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      > * {
        margin: 5px 0;
      }
    }

    #autoClickArea1,
    #autoClickArea2 {
      &:hover {
        width: 25px;
      }
    }
    #autoClickArea0 {
      width: 10px;
      height: 10px;
      background: $gray;
      cursor: pointer;
      &.playing {
        background: #666;
      }
    }
    #autoClickArea1 {
      width: 15px;
      height: 10px;
      background: rgba($cyan, 0.5);
      cursor: pointer;
      &.playing {
        width: 20px;
        background: $cyan;
      }
    }
    #autoClickArea2 {
      width: 15px;
      height: 10px;
      background: rgba($rosy, 0.5);
      cursor: pointer;
      &.playing {
        width: 20px;
        background: $rosy;
      }
    }
  }
</style>
