export const FLOWER_COLORS = Object.freeze({
  primary: '#00a8cc',
  rosy: '#ffa4bb',
  pink: '#ffa4bb',
  cyan: '#7bdef6',
  'cyan-heavy': '#00a8cc',
  'cyan-green': '#58ddcc',
  blue: '#0198e0',
  green: '#89cf13',
  'green-heavy': '#62b873',
  orange: '#f0ae71',
  'orange-red': '#ffc3ab',
  'orange-yellow': '#ffb336',
  purple: '#a89ad4',
  'purple-blue': '#8fb5f5'
})

// const EN_FLOWERS = ['💙', '🤍', '❤️']
export const EN_FLOWERS = Object.freeze([
  'Christopher Nolan',
  'Interstellar',
  'Inception',
  'The Dark Knight Rises',
  'The Dark Knight',
  'Time',
  'Cornfield Chase',
  'S.T.A.Y.',
  'Mountains',
  'Day One',
  'No Time for Caution',
  'Dreaming Of The Crash',
  'Stay',
  'Afraid of Time',
  "Who's They?",
  'Murph',
  'Tick-Tock',
  'First Step',
  'Do Not Go Gentle Into That Good Night',
  'Harvey Two-Face',
  "I'm Not A Hero",
  'A Dark Knight',
  'Like A Dog Chasing Cars',
  'Why So Serious?'
])
// const ZH_FLOWERS = [
//   '富强',
//   '民主',
//   '文明',
//   '和谐',
//   '自由',
//   '平等',
//   '公正',
//   '法治',
//   '爱国',
//   '敬业',
//   '诚信',
//   '友善'
// ]
export const ZH_FLOWERS = Object.freeze([
  '冰菓',
  '我的青春恋爱物语果然有问题',
  '我们仍未知道那天所看见的花的名字',
  '未闻花名',
  '龙与虎',
  'CLANNAD',
  '紫罗兰永恒花园',
  '于离别之朝束起约定之花',
  '朝花夕誓',
  '声之形',
  '凉宫春日的消失',
  '玉子爱情故事',
  '玉子市场',
  '狼的孩子雨和雪',
  '吹响！上低音号',
  '利兹与青鸟',
  'K-ON!',
  '轻音少女',
  '夏日幽灵',
  '新海诚',
  '秒速5厘米',
  '言叶之庭',
  '追逐繁星的孩子',
  '云之彼端，约定的地方',
  '你的名字',
  '天气之子',
  '铃芽户缔',
  '猫的报恩',
  '天降之物',
  '进击的巨人',
  '宫崎骏',
  '千与千寻',
  '天空之城',
  '哈尔的移动城堡',
  '风之谷',
  '幽灵公主',
  '红猪',
  '侧耳倾听',
  '听见涛声',
  '虞美人盛开的山坡',
  '借东西的小人阿莉埃蒂',
  '起风了',
  'DEATH NOTE',
  '死亡笔记',
  '三月的狮子',
  '四月是你的谎言',
  '比宇宙更远的地方',
  '月色真美',
  '堀与宫村',
  '强风吹拂',
  '排球少年',
  '灌篮高手',
  '七龙珠',
  '火影忍者',
  '死神',
  '逆袭的夏亚',
  '通往天国的倒计时',
  '瞳孔中的暗杀者',
  '迷宫的十字路口',
  '世纪末的魔术师',
  '贝克街的亡灵',
  '漆黑的追迹者',
  '战栗的乐谱',
  '心灵想要大声呼喊',
  '来自风平浪静的明天',
  '来自多彩世界的明天',
  'JOJO的奇妙冒险',
  '星际牛仔',
  '命运石之门',
  '魔法禁书目录',
  '某科学的超电磁炮',
  '91Days',
  '寒蝉泣鸣之时',
  '攻壳机动队',
  '空之境界',
  'Fate/Zero',
  'Fate/Stay Night',
  'One Piece',
  '海贼王',
  'HUNTER x HUNTER',
  '全职猎人',
  'OVERLORD',
  '不死者之王',
  'Another',
  'Just Because!',
  'NO GAME NO LIFE',
  '游戏人生',
  '86 - 不存在的战区',
  '心理测量者',
  '文野豪犬',
  '反叛的鲁路修',
  '永生之酒',
  '来自新世界',
  '宝石之国',
  '夏日重现',
  '东京残响',
  '魔法少女小圆',
  'Angel Beats!',
  '终将成为你',
  '言语如苏打般涌现',
  '知晓天空之蓝的人啊',
  '在这世界的角落',
  '在盛夏等待',
  '夏目友人帐',
  '相合之物',
  '恋如雨止',
  '重启咲良田',
  '白箱',
  '一拳超人',
  '咒术回战',
  '鬼灭之刃',
  '刀剑神域',
  'Re：从零开始的异世界生活',
  'Mobile Suit Gundam Unicorn',
  '机动战士高达',
  '闪光的哈萨维',
  '钢之炼金术师',
  'EVA',
  '新世纪福音战士',
  '村上春树',
  '挪威的森林',
  '刺杀骑士团长',
  '且听风吟',
  '太宰治',
  '人间失格',
  '夏目漱石',
  '我是猫',
  '心',
  '追风筝的人',
  '了不起的盖茨比',
  '三体',
  '地球往事',
  '黑暗森林',
  '死神永生'
])
