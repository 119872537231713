/**
 * @file Random number
 * @module util.random
 * @author rosygray <https://github.com/rosygray>
 */

export const randomNumber = (max: number): number => {
  return Math.floor(Math.random() * max) + 1
}

export const randomRange = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const randomArrayIndex = (arr: string[]) => {
  const index = Math.floor(Math.random() * arr.length)
  return arr[index]
}
