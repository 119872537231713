import { reactive, toRefs, nextTick } from 'vue'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'

export interface BackgroundVideoPlayer {
  stateVideoPlayer: {
    player: any
    playing: boolean
  }
  initVideoPlayer: (ctx: {
    videoId: string
    videoOptions: any
    onReadyFunction: () => void
    onLoadedFunction: () => void
  }) => void
  playVideoPlayer: () => void
  pauseVideoPlayer: () => void
  mutedVideoPlayer: () => void
  unmutedVideoPlayer: () => void
  replayVideoPlayer: (ctx?: { delay?: number }) => void
  destroyVideoPlayer: () => void
}

export const useBackgroundVideo = (ctx?: { ctxStateVideoPlayer?: any }) => {
  const { ctxStateVideoPlayer } = ctx ?? {}
  const stateVideoPlayer = ctxStateVideoPlayer
    ? ctxStateVideoPlayer
    : {
        player: null as any,
        playing: false
      }

  const initVideoPlayer = (ctx: { videoId; videoOptions; onReadyFunction; onLoadedFunction }) => {
    const { videoId, videoOptions, onReadyFunction, onLoadedFunction } = ctx
    if (stateVideoPlayer.player) {
      destroyVideoPlayer()
    } else {
      const videoNode = document.getElementById(videoId)
      if (videoNode) {
        nextTick(() => {
          // 控制台提示：video.es.js:218 VIDEOJS: WARN: videojs.mergeOptions is deprecated and will be removed in 9.0; please use videojs.obj.merge instead.
          // stateVideoPlayer.player = videojs(videoId, videoOptions, onReadyFunction)
          stateVideoPlayer.player = videojs(
            videoId,
            videojs.obj.merge(videoOptions),
            onReadyFunction
          )

          stateVideoPlayer.player.on('loadeddata', function () {
            onLoadedFunction()
          })
        })
      }
    }
    // const intervalId = setInterval(() => {
    //   const videoNode = document.getElementById(videoId)
    //   if (videoNode) {
    //     clearInterval(intervalId)
    //     stateVideoPlayer.player = videojs(videoId, videoOptions, onReadyFunction)
    //     stateVideoPlayer.player.on('loadeddata', function () {
    //       console.log('loadeddata:initVideoPlayer')
    //       onLoadedFunction()
    //     })
    //   }
    // }, 100)
  }

  const playVideoPlayer = () => {
    stateVideoPlayer.player?.play()
  }

  const pauseVideoPlayer = () => {
    stateVideoPlayer.player?.pause()
  }

  const toggleMutedVideoPlayer = () => {
    if (stateVideoPlayer.player) {
      stateVideoPlayer.player.muted = !stateVideoPlayer.player.muted
    }
  }

  const mutedVideoPlayer = () => {
    stateVideoPlayer.player.muted = true
  }

  const unmutedVideoPlayer = () => {
    stateVideoPlayer.player.muted = false
  }

  const replayVideoPlayer = (ctx?: { delay?: number }) => {
    const { delay } = ctx ?? {}
    if (stateVideoPlayer.player) {
      stateVideoPlayer.player.currentTime = 0
      stateVideoPlayer.player.pause()
      setTimeout(() => {
        stateVideoPlayer.player?.play()
      }, delay)
    }
  }

  const destroyVideoPlayer = () => {
    if (stateVideoPlayer.player) {
      stateVideoPlayer.player?.dispose()
      stateVideoPlayer.player = null
    }
  }

  return {
    stateVideoPlayer,
    initVideoPlayer,
    playVideoPlayer,
    pauseVideoPlayer,
    mutedVideoPlayer,
    unmutedVideoPlayer,
    toggleMutedVideoPlayer,
    replayVideoPlayer,
    destroyVideoPlayer
  }
}
