<template>
  <div ref="refOfswiperContainer" class="about-page" :class="{ dark: isDarkTheme }">
    <container ref="pageContainer" class="page-content" data-swiper-parallax="-500">
      <!-- <div class="links">
        <template :key="index" v-for="(item, index) in links">
          <component
            class="item"
            :class="item.class"
            :is="item.onClick ? 'button' : 'ulink'"
            :href="item.href"
            :to="item.route"
            @click="item.onClick"
          >
            <span class="left">
              <i class="iconfont" :class="item.icon"></i>
              <span class="text"><i18n v-bind="item.i18n" /></span>
            </span>
            <span class="right">
              <i class="iconfont icon-next"></i>
            </span>
          </component>
        </template>
      </div>
      <div class="statistics">
        <github-statistic />
        <npm-statistic />
        <twitter-statistic />
        <douban-statistic />
      </div> -->
      <div class="plogs" data-swiper-parallax="-200">
        <instagram-media />
      </div>
      <div class="footprint" data-swiper-parallax="-300">
        <footprint-map />
      </div>
      <div class="calendar">
        <aggregate-calendar />
      </div>
      <div class="footer-links" data-swiper-parallax="-700">
        <div class="friendlinks">
          <template v-for="(link, index) in appOption.data?.friend_links || []" :key="index">
            <divider type="vertical" size="lg" v-if="index !== 0" />
            <a :href="link.value" class="item" target="_blank" rel="external nofollow noopener">
              {{ link.name }}
            </a>
          </template>
        </div>
        <div class="speciallinks">
          <template v-for="(item, index) in SPECIAL_LINKS" :key="index">
            <divider type="vertical" size="lg" v-if="index !== 0" />
            <a :href="item.url" class="item" target="_blank" rel="external nofollow noopener">
              {{ item.name }}
            </a>
          </template>
        </div>
      </div>
    </container>
  </div>
</template>

<script lang="ts">
  import { defineComponent, reactive, ref, Ref } from 'vue'
  import { useEnhancer } from '/@/app/enhancer'
  import { RouteName } from '/@/app/router'
  import { useStores } from '/@/stores'
  import { useUniversalFetch } from '/@/universal'
  import { getPageRoute } from '/@/transforms/route'
  import { getTargetCDNURL } from '/@/transforms/url'
  import { GAEventCategories } from '/@/constants/gtag'
  import { META, VALUABLE_LINKS } from '/@/config/app.config'
  import InstagramMedia from './media/instagram.vue'
  // import DoubanStatistic from './statistic/douban.vue'
  // import GithubStatistic from './statistic/github.vue'
  // import TwitterStatistic from './statistic/twitter.vue'
  // import NpmStatistic from './statistic/npm.vue'
  import AggregateCalendar from './calendar/index.vue'
  import FootprintMap from './footprint/index.vue'
  import { useAboutPageMeta, getAdminAvatar, i18ns, SPECIAL_LINKS } from './shared'
  // import SwiperClass, { Swiper, SwiperSlide } from '/@/effects/swiper'

  export default defineComponent({
    name: 'DesktopAboutPage',
    components: {
      // Swiper,
      // SwiperSlide,
      FootprintMap,
      AggregateCalendar,
      // DoubanStatistic,
      // GithubStatistic,
      // NpmStatistic,
      // TwitterStatistic,
      InstagramMedia
    },
    setup() {
      // const refOfswiperContainer: Ref<HTMLElement | null> = ref(null)
      // const pageContainer: Ref<HTMLElement | null> = ref(null)
      // const adminInfoSlogan: Ref<HTMLElement | null> = ref(null)
      const { gtag, globalState, isZhLang, isDarkTheme } = useEnhancer()
      const { adminInfo, appOption } = useStores()

      const handleGTagEvent = (event: string) => {
        gtag?.event(event, {
          event_category: GAEventCategories.About
        })
      }

      const modalState = reactive({
        wechat: false
      })

      const handleOpenWechat = () => {
        modalState.wechat = true
        handleGTagEvent('wechat_modal')
      }

      const handleSponsor = () => {
        globalState.toggleSwitcher('sponsor', true)
        handleGTagEvent('sponsor_modal')
      }

      const handleStatement = () => {
        globalState.toggleSwitcher('statement', true)
        handleGTagEvent('statement_modal')
      }

      const handleFeedback = () => {
        globalState.toggleSwitcher('feedback', true)
        handleGTagEvent('feedback_modal')
      }

      // meta
      useAboutPageMeta()
      // prefetch
      useUniversalFetch(() => Promise.all([adminInfo.fetch(), appOption.fetch()]))

      const links = [
        {
          class: 'lens',
          icon: 'icon-lens',
          i18n: i18ns.lens,
          route: getPageRoute(RouteName.Lens)
        },
        {
          class: 'nft',
          icon: 'icon-opensea',
          i18n: i18ns.nft,
          route: getPageRoute(RouteName.Nft)
        },
        {
          class: 'archive',
          icon: 'icon-quill',
          i18n: i18ns.archive,
          route: getPageRoute(RouteName.Archive)
        },
        {
          class: 'guestbook',
          icon: 'icon-comment',
          i18n: i18ns.guestbook,
          route: getPageRoute(RouteName.Guestbook)
        },
        {
          class: 'feedback',
          icon: 'icon-mail-plane',
          i18n: i18ns.feedback,
          onClick: handleFeedback
        },
        {
          class: 'telegram',
          icon: 'icon-telegram',
          i18n: i18ns.telegramGroup,
          href: VALUABLE_LINKS.TELEGRAM_GROUP
        },
        {
          class: 'discord',
          icon: 'icon-discord',
          i18n: i18ns.discordGroup,
          href: VALUABLE_LINKS.DISCORD_GROUP
        },
        {
          class: 'sponsor',
          icon: 'icon-heart',
          i18n: i18ns.sponsor,
          onClick: handleSponsor
        },
        {
          class: 'statement',
          icon: 'icon-faq',
          i18n: i18ns.statement,
          onClick: handleStatement
        },
        {
          class: 'rss',
          icon: 'icon-rss',
          i18n: i18ns.rss,
          href: VALUABLE_LINKS.RSS
        }
      ]

      return {
        i18ns,
        links,
        META,
        VALUABLE_LINKS,
        SPECIAL_LINKS,
        isZhLang,
        isDarkTheme,
        modalState,
        getTargetCDNURL,
        adminInfo,
        appOption,
        getAdminAvatar,
        handleGTagEvent,
        handleFeedback,
        handleStatement,
        handleOpenWechat
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .swiper {
    // height: calc(100vh - $header-height);
    height: calc(100% - $header-height);
  }

  .play-btn-container {
    position: absolute;
    width: 100%;
    height: 20vh;
    bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-size: 48px;
  }

  .play-btn {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.06em;
    height: 0.56em;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGoAAAA4CAYAAAAVZ21rAAAAAXNSR0IArs4c6QAAAm5JREFUeF7tl7/LEnEcxz1OCBp8nBwSLlwa7gQXeQjBCIyGihAHoWhxKmxqzAgOIfqBs4s03j+QQzjKhYODj9KiQyA43CDcFAiBXTj0EFE95zvuPb1d/by/L3i9pjOiKIpS+lEN7Pf7XRiGt3O53Ke4YOMQyjCM+3EHuvt/A/1+/7TVaj05xMrn82dxXlSoOJYSuPE872aj0Xi03W4rlmV9uQihUBcZSvD/4XB4p1ar3Vuv16e2bQf/QilUgiHiPO37/oNSqVTebDbXHccJ/7ZRqDg2E76Zz+ePLcu6EgTBDcdxvv4Jp1AJR4jzvGmaxnK5fJbJZMzxeHyr2Wx++32nUHFMEm6y2ay5WCyep9PpcDAY3HVd9/uvWIUiRIiLsCzr0nQ6dXe73edCofAwlUqdf+MqVFyLpLtisXjZ9/3XQRB8tG376U+sQpECHIOpVCono9Ho3Wq1el8ul18etgp1jEHibb1ez3me92Y2m/Wq1epbhSLKPxbVbrev9no9dzKZvFCoY+2R7zudzrVut/tKocjiEVwURR8UCjFH3igUWTiKUyjUHHmnUGThKE6hUHPknUKRhaM4hULNkXcKRRaO4hQKNUfeKRRZOIpTKNQceadQZOEoTqFQc+SdQpGFoziFQs2RdwpFFo7iFAo1R94pFFk4ilMo1Bx5p1Bk4ShOoVBz5J1CkYWjOIVCzZF3CkUWjuIUCjVH3ikUWTiKUyjUHHmnUGThKE6hUHPknUKRhaM4hULNkXcKRRaO4hQKNUfeKRRZOIpTKNQceadQZOEoTqFQc+SdQpGFoziFQs2RdwpFFo7izkOhD2jHM/ADHdbwJ8875eoAAAAASUVORK5CYII=)
      no-repeat 0 0/100% 100%;
    cursor: pointer;
    transition: all 300ms;
    opacity: 0.5;
    filter: drop-shadow(2px 4px 6px black);

    &:hover {
      // background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGoAAAA4CAYAAAAVZ21rAAAAAXNSR0IArs4c6QAAAodJREFUeF7tl09Ik3Ecxt/xRjGxQo0iwyKIKV6EGjIjp7YwyRiIJy3wqCEMhCLGCH5EZandu3jwoIdu6sE//aW8F0SB4J9Dh8HCiTI3MZzhIYmo/O2J9zk9u+77/D7w+ZxeX3RkfsfRj2pgJ7+d+55db57uDc3Zgn27oSa6KqO2A939v4GaLlNbUd/es5lNN7+MNXyweVGhbCx5cHPh1mDjyeDVm9mNbxdfxyKL+yEUaj9DHv4f6nt2ray67vp6Kln7PtGS/BdKoTwMYfP0pcRYx5HTVcHtZCo0Y1rSf9solI1Nj28a7493+4+Vl+dSmfBb05T5E06hPI5g9bzr+iL9U30H/MXu15XZK1+M2fp9p1BWJr0/Olh02G14MBn3uW56dvVVq2NM/leqQnnfwJrgLz11KGyem/zW5qcXtyOdjuPsfeMqlLVGzmFxRaAoHB/tz62lpt7EW3t/UhWK478gSsm580fr7gwPZJJLw+9M+73dsUIVpJB3fCIYOR7sfvp4bfnz0NyjG08Uiue+YNLZy51nqjvumvTCx4RCFayPOwhEewKVbbGHCsX1DtGiI/MTCgWp444UiusbpikUrI47VCiub5imULA67lChuL5hmkLB6rhDheL6hmkKBavjDhWK6xumKRSsjjtUKK5vmKZQsDruUKG4vmGaQsHquEOF4vqGaQoFq+MOFYrrG6YpFKyOO1Qorm+YplCwOu5Qobi+YZpCweq4Q4Xi+oZpCgWr4w4ViusbpikUrI47VCiub5imULA67lChuL5hmkLB6rhDheL6hmkKBavjDhWK6xumKRSsjjtUKK5vmKZQsDruUKG4vmGaQsHquEOF4vqGaXuh4Bc0pBn4AfBTumBicdXLAAAAAElFTkSuQmCC);
      opacity: 0.8;
      scale: 1.05;
      b {
        // border-left-color: #4ea1d9;
        border-left-color: $rosy;
      }
    }

    b {
      width: 0;
      height: 0;
      margin-left: 0.14em;
      border: 0.1em solid rgba(0, 0, 0, 0);
      border-left: 0.16em solid #fff;
      transition: all 500ms;
      position: relative;
      z-index: 1;
    }
  }
  .play-btn-text {
    font-family: 'PingFang-Bold';
    font-size: 12px;
    width: 250px;
    margin: 5px -15px 0;
    letter-spacing: 1px;
    scale: 0.8;
  }

  .youtube-modal {
    // width: 58vw;
    // height: 58vh;
    width: calc(58vw * (1 / var(--scale-factor)));
    height: calc(58vw * (1 / var(--scale-factor)));
    position: relative;
    background: $black !important;
  }

  .scroll-btn-container {
    position: absolute;
    width: 100%;
    height: 20vh;
    bottom: 10vh;
  }
  .scroll-btn {
    position: absolute;
    // bottom: 1.68em;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1em;
    margin: 0 auto;
    font-size: 88px;
    transition: transform 800ms, -webkit-transform 800ms;
    transition-delay: 300ms;
    z-index: 9999;
    cursor: pointer;

    .scroll-btn__mouse {
      width: 0.26em;
      height: 0.4em;
      // background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSImI3g1NzA2OyYjeDg5RDI7XzFfIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIg0KCSB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDE3IDI3IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxNyAyNzsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGcgaWQ9IiYjeDU3MDY7JiN4ODlEMjsiPg0KCTxnPg0KCQk8cGF0aCBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7ZmlsbDojRkZGRkZGOyIgZD0iTTguNSwwQzMuODA2LDAsMCwzLjgyOCwwLDguNTQ5djkuODk5DQoJCQljMCw0LjcyMiwzLjgwNiw4LjU0OSw4LjUsOC41NDljNC42OTUsMCw4LjUtMy44MjgsOC41LTguNTQ5VjguNTQ5QzE3LDMuODI4LDEzLjE5NSwwLDguNSwweiBNMTUsMTguNjA3DQoJCQljMCwzLjYxMS0yLjkxLDYuNTM4LTYuNSw2LjUzOGMtMy41OSwwLTYuNS0yLjkyNy02LjUtNi41MzhWOC41NDljMC0zLjYxMSwyLjkxLTYuNTM4LDYuNS02LjUzOGMzLjU5LDAsNi41LDIuOTI3LDYuNSw2LjUzOA0KCQkJVjE4LjYwN3ogTTguNSw1LjAyOUM3LjY3Miw1LjAyOSw3LDUuNzA1LDcsNi41Mzh2NS4wMjljMCwwLjgzMywwLjY3MiwxLjUwOSwxLjUsMS41MDlTMTAsMTIuNCwxMCwxMS41NjdWNi41MzgNCgkJCUMxMCw1LjcwNSw5LjMyOCw1LjAyOSw4LjUsNS4wMjl6Ii8+DQoJPC9nPg0KPC9nPg0KPC9zdmc+DQo=);
      // no-repeat top center/0.2em auto;
    }

    .scroll-btn__arrow {
      position: relative;
      // width: 0.08em;
      // height: 0.3em;
      margin-top: 0.1em;
      img {
        width: 0.08em;
        transform: translateY(-50%);
        animation: scrollmove 1.4s ease-in-out 0.4s infinite;
      }
      @keyframes scrollmove {
        0% {
          transform: translateY(-150%);
          opacity: 0;
        }
        20% {
          opacity: 1;
        }
        80% {
          transform: translateY(300%);
        }
        100% {
          transform: translateY(300%);
          opacity: 0;
        }
      }
    }

    .scroll-btn__line {
      width: 0;
      height: 0.8em;
      margin-top: 0.1em;
      border-right: 2px solid #333;
    }
  }

  .qrcode-modal {
    $image-size: 16rem;
    width: 23rem;
    height: 35rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &.wechat {
      --item-primary: #{$wechat-primary};
    }

    .background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 45%;
      background: var(--item-primary);
    }

    .image {
      z-index: $z-index-normal + 1;
      width: $image-size;
      // height: $image-size;
      margin-bottom: 2rem;
      background-color: $module-bg-opaque;
      @include radius-box($sm-radius);
    }

    .text {
      font-weight: bold;
      color: var(--item-primary);
    }
  }

  .about-page {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    // height: 100%;
    height: calc(100vh * (1 / var(--scale-factor)) - $header-height);
    overflow: hidden;

    &.dark {
      .page-banner {
        .background-wrapper {
          background-color: #222;
        }
        .play-btn {
          filter: drop-shadow(2px 4px 6px white);
        }

        .content {
          // background-color: rgba($black, 0.5);
          .name,
          .slogan {
            color: $white;
          }
        }
      }
    }

    .page-banner {
      position: relative;
      $banner-height: 20rem;
      height: 100%;
      overflow: hidden;

      .background-wrapper {
        // display: block;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        // height: $banner-height;
        height: 100%;
        top: 0;
        left: 0;
        overflow: hidden;
        z-index: $z-index-normal + 1;
        // background-color: $module-bg-darker-1;
        background-color: $white;
      }
      .content {
        // position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        // justify-content: flex-start;
        // width: 100%;
        // background-color: $module-bg;
        // @include radius-box($lg-radius);
        // background-image: url('/images/page-about/background.png'),
        // linear-gradient($module-bg-opaque 40%, #00000000 100%);
        // background-size: contain;
        // background-repeat: repeat-x;
        // background-blend-mode: lighten;

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background-color: rgba($white, 0.3);
        z-index: 1;
        .avatar {
          display: none;
          $size: 8rem;
          width: $size;
          height: $size;
          z-index: $z-index-normal + 2;
          box-sizing: content-box;
          // margin-top: $banner-height - 4rem;
          border: 8px solid $module-bg;
          border-radius: 100%;
          overflow: hidden;
          background-color: $module-bg;
          transition: transform $transition-time-slow;
          &:hover {
            transform: rotate(360deg);
          }
        }

        .name,
        .slogan {
          text-align: center;
          // color: $text;
          // color: $black;
          color: $white;
          // backdrop-filter: blur(4px);
        }

        .name {
          margin-top: $sm-gap;
          margin-bottom: $gap;
          font-family: 'Comic Sans MS', 'Helvetica Neue', Microsoft Yahei, -apple-system, sans-serif;
          font-size: 50px;
          letter-spacing: 8px;
        }

        .slogan {
          font-family: $font-family-kanji;
          font-size: 18px;
          letter-spacing: 6px;
        }

        .biography {
          margin-bottom: $gap * 2;
          // font-size: $font-size-h3;
          font-size: $font-size-h2;
          color: $white;
        }

        .socials {
          $button-size: 3rem;
          display: flex;
          justify-content: center;
          height: $button-size;
          margin-top: 5rem;
          margin-bottom: $gap * 2;

          .normal {
            display: inline-flex;
            align-items: center;
            margin-right: $gap;

            .item {
              padding: 0 $gap;
              margin-right: $gap;
              height: 100%;
              display: inline-flex;
              align-items: center;
              border-radius: $sm-radius;
              color: $white;
              transition: all $transition-time-fast;

              .iconfont {
                font-size: $font-size-h4;
                margin-right: $sm-gap;
              }

              .text {
                font-weight: bold;
              }

              &.github {
                background-color: $github-primary;
                &:hover {
                  background-color: $github-primary-hover;
                }
              }
              &.twitter {
                background-color: $twitter-primary;
                &:hover {
                  background-color: $twitter-primary-hover;
                }
              }
              &.youtube {
                margin: 0;
                background-color: $youtube-primary;
                &:hover {
                  background-color: mix($black, $youtube-primary, 8%);
                }
              }
              &.instagram {
                opacity: 0.8;
                background: $instagram-primary;
                background: $instagram-gradient;
                &:hover {
                  opacity: 1;
                }
              }
            }
          }

          > .mini {
            display: flex;

            > .item {
              display: inline-block;
              width: $button-size;
              height: $button-size;
              line-height: $button-size;
              margin-right: $gap;
              text-align: center;
              border-radius: $sm-radius;
              color: $white;
              opacity: 0.8;
              transition: all $transition-time-fast;

              &:hover {
                opacity: 1;
              }

              .iconfont {
                font-size: $font-size-h4;
              }

              &.wechat {
                background-color: $wechat-primary;
              }
              &.telegram {
                background-color: $telegram-primary;
              }
              &.douban {
                background-color: $douban-primary;
              }
              &.stackoverflow {
                background-color: $stackoverflow-primary;
              }
              &.algorithm {
                background-color: $leetcode-primary;
              }
              &.quora {
                background-color: $quora-primary;
              }
              &.linkedin {
                background-color: $linkedin-primary;
              }
            }
          }
        }
      }
    }

    .page-content {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      // min-height: calc(100vh - $header-height - $footer-height);
      min-height: calc(100% - $header-height - $footer-height);
      // margin: $gap * 2 0;
      overflow: hidden;
    }

    .links {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: $gap * 2;
      width: 100%;
      margin-bottom: 2rem;

      .item {
        height: 5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 1em 0 2em;
        @include common-bg-module($transition-time-fast);
        @include radius-box($lg-radius);
        &.discord {
          --item-primary: #{$discord-primary};
          --item-hover: white;
        }
        &.telegram {
          --item-primary: #{$telegram-primary};
          --item-hover: white;
        }
        &.sponsor {
          --item-primary: #{$red};
          --item-hover: white;
        }
        &.statement {
          --item-primary: #{$rosygray};
          --item-hover: white;
        }
        &.rss {
          --item-primary: #{$rss-primary};
          --item-hover: white;
        }
        &:hover {
          background-color: var(--item-primary, $primary);
          .left {
            .iconfont,
            .text {
              color: var(--item-hover, $text-reversal);
            }
          }

          .right {
            opacity: 1;
            transform: translateX(-$sm-gap);
            color: var(--item-hover, $text-reversal);
          }
        }

        .left {
          .iconfont {
            font-size: $font-size-h3;
            margin-right: 0.8em;
            color: var(--item-primary, $primary);
          }

          .text {
            letter-spacing: 1px;
            color: $text-secondary;
            font-size: $font-size-h4;
            font-weight: bold;
          }
        }

        .right {
          color: $text-divider;
          opacity: 0.4;
          transition: opacity $transition-time-fast, transform $transition-time-normal;
        }
      }
    }

    .statistics {
      margin-bottom: $gap * 2;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: $gap * 2;
    }

    .plogs,
    .vlogs,
    .calendar {
      margin-bottom: $gap * 2;
      border-radius: $lg-radius;
      padding: $gap;
      @include common-bg-module();
    }

    .footprint {
      width: 100%;
      margin-bottom: $gap * 2;
    }

    .footer-links {
      display: flex;
      justify-content: space-between;
      padding: 2rem $lg-gap;
      @include common-bg-module();
      @include radius-box($lg-radius);

      .item {
        font-weight: bold;
        @include text-underline();
      }

      .speciallinks {
        .item {
          color: $text-disabled;
          &:hover {
            color: $text;
          }
        }
      }
    }
  }
</style>
