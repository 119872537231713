<template>
  <swiper
    class="swiper"
    :set-wrapper-size="true"
    :mousewheel="false"
    :observe-parents="true"
    :grab-cursor="false"
    :simulate-touch="true"
    :preload-images="false"
    :lazy="false"
    :pagination="{ clickable: true }"
    :autoplay="{ delay: 10000, disableOnInteraction: false }"
  >
    <swiper-slide v-for="(banner, index) in bannerList" :key="index">
      <page-banner class="page-banner" :position="banner.position" :image="banner.image">
        <template #title>
          <webfont>
            <i18n :zh="banner.title_zh" :en="banner.title_en" />
          </webfont>
        </template>
        <template #description>
          <i18n>
            <template #zh>{{ banner.description_zh }}</template>
            <template #en>{{ banner.description_en }}</template>
          </i18n>
        </template>
      </page-banner>
    </swiper-slide>
  </swiper>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { Swiper, SwiperSlide } from '/@/effects/swiper'
  import PageBanner from '/@/components/common/banner.vue'

  export default defineComponent({
    name: 'PageBannerSwiper',
    components: {
      Swiper,
      SwiperSlide,
      PageBanner
    },
    props: {
      bannerList: {
        type: Object,
        required: true
      }
    },
    setup() {}
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';
</style>
