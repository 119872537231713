<template>
  <div v-if="!isChangingLanguage">
    <transition name="fade" mode="out-in">
      <div v-if="isShowCurrentSaying" class="saying" :class="{ dark: isDarkTheme }">
        <p class="text">{{ currentSaying.text }}</p>
        <p class="source">
          <span class="punctuation">——</span>
          <span class="author">{{ currentSaying.author }}</span>
          <span class="punctuation">/</span>
          <span>{{ currentSaying.source }}</span>
        </p>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, onMounted, onBeforeUnmount, nextTick, watch } from 'vue'
  import { useEnhancer } from '/@/app/enhancer'
  import { Language } from '/@/language'
  import { randomRange } from '/@/utils/random'
  import { ZH_SAYING, EN_SAYING } from '/@/config/saying.config'

  export default defineComponent({
    name: 'Saying',
    // props: {},
    setup() {
      const { isZhLang, isDarkTheme } = useEnhancer()
      const getSayingTextArray = () => {
        return isZhLang.value ? ZH_SAYING : EN_SAYING
      }
      let currentSaying = ref(ZH_SAYING[0])
      let isShowCurrentSaying = ref(true)
      let isChangingLanguage = ref(false)

      let randomSayingTimer: string | number | any = null

      const changingLanguage = () => {
        isShowCurrentSaying.value = false
        isChangingLanguage.value = true
        nextTick(() => {
          isChangingLanguage.value = false
        })
      }
      // const initRandomSaying = () => {
      //   clearInterval(randomSayingTimer)
      //   const randomSaying = () => {
      //     isShowCurrentSaying.value = false
      //     nextTick(() => {
      //       const sayingArray = getSayingTextArray()
      //       currentSaying.value = sayingArray[randomRange(0, sayingArray.length - 1)]
      //       isShowCurrentSaying.value = true
      //     })
      //   }
      //   randomSaying()
      //   randomSayingTimer = window.setInterval(randomSaying, 5000)
      // }

      const initRandomSaying = () => {
        clearInterval(randomSayingTimer)
        let sayings = getSayingTextArray().slice() // 复制数组
        let count = 0 // 记录已随机过的元素个数
        const randomSaying = () => {
          isShowCurrentSaying.value = false
          nextTick(() => {
            if (count >= sayings.length) {
              // 所有元素都已经随机过一遍，重新打乱数组
              sayings = shuffleArray(sayings)
              count = 0
            }
            currentSaying.value = sayings[count]
            count++
            isShowCurrentSaying.value = true
          })
        }
        randomSaying()
        randomSayingTimer = window.setInterval(randomSaying, 5000)
      }

      // 打乱数组
      const shuffleArray = (arr) => {
        let currentIndex = arr.length
        let randomIndex
        while (currentIndex != 0) {
          randomIndex = Math.floor(Math.random() * currentIndex)
          currentIndex--
          ;[arr[currentIndex], arr[randomIndex]] = [arr[randomIndex], arr[currentIndex]]
        }
        return arr
      }

      watch(isZhLang, () => {
        changingLanguage()
        initRandomSaying()
      })

      onMounted(() => {
        setTimeout(() => {
          initRandomSaying()
        }, 5000)
      })

      onBeforeUnmount(() => {
        clearInterval(randomSayingTimer)
      })

      return {
        Language,
        isZhLang,
        isDarkTheme,
        currentSaying,
        isShowCurrentSaying,
        isChangingLanguage
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  .fade-enter-to,
  .fade-leave-from {
    opacity: 1;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 5s ease;
  }

  .saying {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 10px 0 0 10px;
    color: #333;
    font-family: $font-family-kanji;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    scale: 0.95;
    &.dark {
      color: #ededed;
    }
    .text {
      font-size: 12.5px;
      letter-spacing: 2px;
    }
    .source {
      margin: 20px 0 0 0;
      scale: 0.9;
      .author {
        margin: 0 5px 0;
      }
      .punctuation {
        margin: 1px 0 2px;
      }
    }
  }
</style>
