/**
 * @file BFF server config
 * @module config.bff
 * @author rosygray <https://github.com/rosygray>
 */

export const BFF_TUNNEL_PREFIX = '/_tunnel'
export const BFF_PROXY_PREFIX = '/_proxy'
export const BFF_PROXY_ALLOWLIST = [
  'https://rosygray.com',
  'http://rosygray.com',
  'https://cdn.rosygray.com',
  'http://cdn.rosygray.com',
  'https://blog.rosygray.com',
  'http://blog.rosygray.com',
  'https://api.rosygray.com',
  'http://api.rosygray.com',
  'https://admin.rosygray.com',
  'http://admin.rosygray.com',
  'https://127.0.0.1',
  'http://127.0.0.1',
  'https://localhost',
  'http://localhost'
]

export const getBFFServerPort = () => Number(process.env.PORT || 10082)
