<template>
  <div
    class="banner"
    :class="{ mobile: isMobile, dark: isDarkTheme }"
    :style="{ height: `${height}vh` }"
  >
    <div
      class="background"
      :style="{ backgroundImage: `url(${imageURL})`, backgroundPositionY: `${position}%` }"
    ></div>
    <div class="my-content" :class="{ blur: Boolean(blur) }" :style="{ '--blur': `${blur}px` }">
      <h2 class="title" :style="{ color: color }">
        <slot name="title"></slot>
      </h2>
      <div class="description" :style="{ color: color }">
        <slot name="description"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { getTargetCDNURL } from '/@/transforms/url'
  import { useEnhancer } from '/@/app/enhancer'

  export default defineComponent({
    name: 'PageBanner',
    props: {
      image: {
        type: String,
        required: true
      },
      height: {
        type: [Number, String],
        default: 50
      },
      position: {
        type: Number,
        default: 20
      },
      color: {
        type: String
      },
      blur: {
        type: Number,
        default: 0
      },
      isMobile: {
        type: Boolean,
        default: false
      }
    },
    setup(props) {
      const { isDarkTheme } = useEnhancer()
      const imageURL = getTargetCDNURL(props.image)
      return {
        isDarkTheme,
        // imageURL: getTargetCDNURL(props.image)
        imageURL
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .banner {
    position: relative;
    // height: $full-column-page-banner-height;
    z-index: $z-index-normal;
    &.dark {
      .background {
        filter: brightness(0.8);
        background-color: rgba($black, 0.6);
      }
      .my-content {
        color: $white;
      }
    }

    .background {
      position: absolute;
      z-index: $z-index-underground;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // background-color: $module-bg-darker-1;
      // background-color: rgba($purple-light, 0.9);
      background-color: rgba($gray-light, 0.9);
      // background-color: rgba($gray, 0.5);
      background-size: cover;
      background-position-x: center;
    }

    .my-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      // color: $white;
      color: $black;
      &.blur {
        @include backdrop-blur(var(--blur));
      }

      .title {
        // font-size: 3em;
        font-size: 2.8em;
        margin-top: 0;
        // margin-bottom: $lg-gap * 2;
        margin-bottom: $lg-gap;
      }

      .description {
        font-size: $font-size-h4;
        font-weight: bold;
      }
    }

    &.mobile {
      height: $mobile-banner-height;
      margin-top: -$lg-gap;
      margin-left: -$gap;
      margin-right: -$gap;

      .title {
        font-size: 2em;
      }
    }
  }
</style>
