<template>
  <div class="wall" :class="{ dark: isDarkTheme }">
    <transition name="module" mode="out-in">
      <div
        v-if="isImageLoaded"
        ref="picture-box"
        :key="currentImage.name"
        :title="currentImage.name"
        class="picture-box"
        :style="{
          backgroundImage: `url('${currentImageOriginUrl}')`,
          backgroundSize: bgSize
        }"
      />
      <loading v-else />
    </transition>
    <div class="story-box">
      <template v-if="currentImage.name">
        <h2 class="title">{{ currentImage.name }}</h2>
        <p class="sub-title">{{ currentImage.collection.name }}</p>
      </template>
      <template v-else>
        <h2 class="title lonely">{{ currentImage.collection.name }}</h2>
      </template>
      <p class="desc">{{ currentImage.description }}</p>
      <div class="tools">
        <ulink class="button" :href="currentImage.permalink" :title="currentImage.name">
          <uimage cdn src="/images/third-party/opensea.svg" class="icon-opensea"></uimage>
        </ulink>
        <button class="button" title="Prev" :disabled="currentIndex <= 0" @click="viewPrev">
          <i class="iconfont icon-prev"></i>
        </button>
        <button
          class="button"
          title="Next"
          :disabled="currentIndex >= medias.length - 1"
          @click="viewNext"
        >
          <i class="iconfont icon-next"></i>
        </button>
        <button class="button" title="Close" @click="handleClose">
          <i class="iconfont icon-cancel"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, computed, ComputedRef, onMounted } from 'vue'
  import { useEnhancer } from '/@/app/enhancer'
  import { getTargetProxyURL } from '/@/transforms/url'
  import type { OpenSeaAssetItem } from '/@/server/getters/opensea'

  export enum ZoomIntoViewEvent {
    Close = 'close',
    LoadMore = 'loadMore'
  }

  export default defineComponent({
    name: 'ZoomIntoView',
    components: {},

    props: {
      medias: {
        type: Array,
        required: true,
        default: () => []
      },
      index: {
        type: Number,
        default: 0
      }
    },

    emits: [ZoomIntoViewEvent.Close, ZoomIntoViewEvent.LoadMore],
    setup(props, context) {
      const { isDarkTheme } = useEnhancer()

      let currentIndex = ref<number>(props.index)
      let isImageLoaded = ref<boolean>(false)
      const currentImage: ComputedRef<OpenSeaAssetItem> = computed(() => {
        const result = props.medias?.length && props.medias?.[currentIndex.value]
        return result as OpenSeaAssetItem
      })
      let currentImageOriginUrl = ref<string>('')
      const bgSize = ref('')

      const loadImage = () => {
        const img = new Image()
        img.onload = function (this: HTMLImageElement) {
          const width = this.width
          const height = this.height
          bgSize.value = width > height ? 'cover' : 'contain'
          isImageLoaded.value = true
        } as ((this: GlobalEventHandlers) => any) | null
        img.src = currentImageOriginUrl.value
      }

      const zoomIntoViewImageOriginUrl = () => {
        isImageLoaded.value = false
        const imageUrl = currentImage.value?.image_url
        const regex = /\/files\/([^?]+)/
        const match = regex.exec(imageUrl)
        const imageFileName = match?.[1]
        const imageOriginUrl = `https://dl.openseauserdata.com/cache/originImage/files/${imageFileName}`
        currentImageOriginUrl.value = getTargetProxyURL(imageOriginUrl)
        loadImage()
        return imageOriginUrl
      }

      const viewPrev = () => {
        if (currentIndex.value !== 0) {
          currentIndex.value--
          zoomIntoViewImageOriginUrl()
        }
      }

      const viewNext = () => {
        if (currentIndex.value !== props.medias.length) {
          currentIndex.value++
          zoomIntoViewImageOriginUrl()
        }
        if (currentIndex.value > props.medias.length - 10) {
          context.emit(ZoomIntoViewEvent.LoadMore)
        }
      }

      const handleClose = () => context.emit(ZoomIntoViewEvent.Close)

      onMounted(() => {
        zoomIntoViewImageOriginUrl()
      })

      return {
        isDarkTheme,
        getTargetProxyURL,

        currentIndex,
        currentImage,
        currentImageOriginUrl,
        zoomIntoViewImageOriginUrl,
        bgSize,
        isImageLoaded,

        viewPrev,
        viewNext,
        handleClose
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .wall {
    position: relative;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    // width: 88vw;
    // height: 88vh;
    width: calc(88vw * (1 / var(--scale-factor)));
    height: calc(88vw * (1 / var(--scale-factor)));

    &.dark {
      .story-box {
        .title,
        .sub-title,
        .desc {
          @include title-shadow();
        }
      }
    }

    .picture-box {
      width: 100%;
      height: 100%;
      overflow: hidden;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    .story-box {
      position: absolute;
      display: flex;
      flex-direction: column;
      // align-items: flex-end;
      padding: 2rem;
      background-color: $module-bg-translucent;
      bottom: 0;
      width: 100%;
      height: auto;
      max-height: 28%;

      .title,
      .sub-title,
      .desc {
        // @include title-shadow();
        color: #333;
      }

      .title {
        margin-top: 0;
        margin-bottom: 0.1em;
        text-shadow: 0px 0px 0px #333, 0px 0px 2px rgba(255, 255, 255, 0.3),
          0px 0px 4px rgba(255, 255, 255, 0.3);
        &.lonely {
          margin-bottom: 3rem;
        }
      }

      .sub-title {
        margin-bottom: 0.9em;
        font-weight: bold;
      }

      .desc {
        max-width: 50%;
        line-height: 1.8rem;
        // margin-bottom: $gap * 2;
        margin-bottom: 0;
        padding: 0 2rem 0 0;
        overflow-y: auto;
        user-select: all;
        font-size: 12px;
        font-weight: bold;
        &::selection {
          text-shadow: none;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 0;
          background-color: $module-bg-darker-3;
          @include background-transition();
          &:hover {
            background-color: $module-bg-darker-4;
          }
        }
      }

      .tools {
        // display: flex;
        $size: 3rem;
        position: absolute;
        bottom: 2rem;
        right: 2rem;
        display: flex;

        .icon-opensea {
          width: 58%;
          height: 100%;
          filter: grayscale(1);
        }

        .button {
          display: block;
          width: $size;
          height: $size;
          line-height: $size;
          // margin-right: $gap;
          margin-left: $gap;
          text-align: center;
          background-color: $module-bg;
          @include radius-box($xs-radius);

          &[disabled] {
            opacity: 0.6;
          }

          &:not([disabled]) {
            &:hover {
              background-color: $module-bg-darker-1;
            }
          }
        }
      }
    }

    .loading-wrapper {
      position: absolute;
      top: 15%;
    }
  }
</style>
