<template>
  <div class="statement">
    <div class="my-content">
      <markdown :markdown="appOption.data?.statement" :compact="true" />
      <br />
      <hr />
      <p v-if="appOption.data" class="email">
        <a :href="emailLink(appOption.data.site_email)" class="link" target="_blank">
          <i class="iconfont icon-mail"></i>
          <span class="text">{{ appOption.data.site_email }}</span>
        </a>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { useStores } from '/@/stores'
  import { emailLink } from '/@/transforms/email'
  import Markdown from '/@/components/common/markdown.vue'

  export default defineComponent({
    name: 'Statement',
    components: { Markdown },
    setup() {
      const { appOption } = useStores()
      return { appOption, emailLink }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .statement {
    background-color: $module-bg-opaque !important;
    overflow-y: scroll !important;

    .my-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 100px 30px 0px;
      letter-spacing: 1px;

      // padding: 0 2em;
      // width: 60rem;
      // min-height: 70rem;
      width: 58rem;
      max-height: 68rem;

      hr {
        margin-top: 5rem;
      }

      .email {
        .link {
          color: $text-secondary;

          .iconfont {
            margin-right: $xs-gap;
          }
          .text {
            font-weight: bold;
          }

          &:hover {
            color: $link-hover;
          }
        }
      }
    }
  }
</style>
