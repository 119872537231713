<template>
  <div id="wallflower">
    <ul v-if="stateGarden && stateGarden.flowers.length" class="garden-box">
      <flower
        v-for="(flower, index) in stateGarden.flowers"
        :key="flower.id"
        :z-index="index + 1"
        :options="flower"
        :state-auto-click="stateAutoClick"
        @ended="animationEnd"
      />
    </ul>
  </div>
</template>

<script lang="ts">
  import { defineComponent, onMounted, onBeforeUnmount, toRef, computed, watch } from 'vue'
  import { useEnhancer } from '/@/app/enhancer'
  import { useWallflower } from '/@/components/widget/wallflower/wallflower'
  import { ZH_FLOWERS, EN_FLOWERS } from '/@/config/flower.config'
  import Flower from './flower.vue'

  export interface IFlower {
    id: number
    x: number
    y: number
    text: string
  }

  export default defineComponent({
    name: 'WallFlowerGarden',
    components: {
      Flower
    },
    props: {
      stateAutoClick: {
        type: Object,
        required: true
      }
    },
    setup(props) {
      const { stateGarden, handleGardenClick, handleAnimationEnd, handleAutoClickArea } =
        useWallflower()
      const { isZhLang } = useEnhancer()
      const currentAutoClickMode = toRef(props.stateAutoClick, 'currentAutoClickMode')
      const getFlowerTextArray = () => {
        return isZhLang.value ? ZH_FLOWERS : EN_FLOWERS
      }

      const gradenClick = (event: MouseEvent | any) => {
        handleGardenClick(event, {
          stateGarden,
          stateAutoClick: props.stateAutoClick,
          getFlowerTextArray
        })
      }
      const animationEnd = (id: number) => {
        handleAnimationEnd(id, {
          stateGarden
        })
      }

      watch(
        () => currentAutoClickMode.value,
        (newVal, oldVal) => {
          if (newVal !== oldVal) {
            handleAutoClickArea(props.stateAutoClick, gradenClick)
          }
        },
        {
          deep: true
          // immediate: true
        }
      )

      onMounted(() => {
        window?.addEventListener('click', gradenClick)
      })
      onBeforeUnmount(() => {
        window?.removeEventListener('click', gradenClick)
      })

      return {
        stateGarden,
        animationEnd,
        gradenClick,
        handleAutoClickArea
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  #wallflower {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 0;
    list-style: none;
    z-index: -1;

    .garden-box {
      width: 100%;
      height: 100%;
      padding: 0;
    }
  }
</style>
