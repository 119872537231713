<template>
  <div class="nft-page">
    <!-- <page-banner :position="60" image="/images/page-nft/banner.jpg" class="banner"> -->
    <page-banner :position="60" :image="bannerImageUrl" class="banner">
      <template #title>
        <webfont>
          <!-- <i18n zh="韶光抚月 裁雨为虹" en="READ Bar" /> -->
        </webfont>
      </template>
      <template #description>
        <!-- <i18n zh="由我铸造 / 收藏的独一无二的数字藏品" en="Unique NFTs minted by me" /> -->
      </template>
    </page-banner>

    <container class="page-content">
      <placeholder :data="totalAssets.length" :loading="openseaAssets.fetching && isFirstFetching">
        <template #placeholder>
          <empty class="asset-empty" key="empty">NULL</empty>
        </template>
        <template #loading>
          <ul class="asset-skeleton" key="skeleton">
            <li v-for="item in 10" :key="item" class="item">
              <skeleton-base class="image" />
              <skeleton-paragraph class="paragraph" :lines="5" :align="true" line-height="1em" />
            </li>
          </ul>
        </template>
        <template #default>
          <div class="asset-list-wrapper">
            <ul class="asset-list">
              <li class="asset" :key="index" v-for="(asset, index) in totalAssets">
                <div
                  class="image"
                  :style="{ backgroundImage: `url('${getTargetProxyURL(asset.image_url)}')` }"
                  @click="openZoomIntoViewModal(asset.image_url, index)"
                >
                  <!-- <ulink
                    class="contract"
                    :href="getEtherscanURL(asset.asset_contract.address)"
                    @click="handleGTagEvent('asset_contract_link', asset.name)"
                  >
                    <i class="iconfont icon-coin"></i>
                    <span class="text">{{ asset.asset_contract.schema_name }}</span>
                  </ulink> -->
                  <ulink
                    class="external"
                    v-if="asset.external_link"
                    :href="asset.external_link"
                    @click="handleGTagEvent('asset_external_link', asset.name)"
                  >
                    <i class="iconfont icon-new-window-s"></i>
                  </ulink>
                  <div class="links">
                    <ulink
                      class="item"
                      v-if="asset.collection.discord_url"
                      :href="asset.collection.discord_url"
                      @click="handleGTagEvent('asset_collection_discord_url', asset.name)"
                    >
                      <i class="iconfont icon-discord"></i>
                    </ulink>
                    <ulink
                      class="item"
                      v-if="asset.collection.telegram_url"
                      :href="asset.collection.telegram_url"
                      @click="handleGTagEvent('asset_collection_telegram_url', asset.name)"
                    >
                      <i class="iconfont icon-telegram"></i>
                    </ulink>
                  </div>
                </div>
                <div class="my-content">
                  <ulink
                    class="name"
                    :title="asset.name"
                    :href="asset.permalink"
                    @click="handleGTagEvent('asset_name_link', asset.name)"
                  >
                    {{ asset.name }}
                  </ulink>
                  <ulink
                    class="collection"
                    :title="asset.collection.name"
                    :href="getOpenSeaCollectionURL(asset.collection.slug)"
                    @click="handleGTagEvent('asset_collection_link', asset.name)"
                  >
                    {{ asset.collection.name }}
                  </ulink>
                  <div class="description" :title="asset.description">
                    <markdown :markdown="asset.description" :sanitize="true" :compact="true" />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </template>
      </placeholder>
      <loading :loading="openseaAssets.fetching && !isFirstFetching" :color="'#999'" />
    </container>

    <div class="view-more-nft">
      <ulink class="link" :href="VALUABLE_LINKS.OPENSEA">
        <i18n>
          <template #zh> 在 <i class="iconfont icon-opensea"></i> OpenSea 查看更多 NFTs </template>
          <template #en> View all NFTs on <i class="iconfont icon-opensea"></i> OpenSea </template>
        </i18n>
      </ulink>
    </div>

    <client-only>
      <popup v-model:visible="isOnZoomIntoViewModal" :mask-close="false" :scroll-close="false">
        <zoom-into-view
          :medias="totalAssets"
          :index="zoomIntoViewActiveIndex"
          @close="closeZoomIntoViewModal"
          @load-more="loadMore"
        />
      </popup>
    </client-only>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, Ref, watch, nextTick, onMounted, onUnmounted } from 'vue'
  import { LanguageKey } from '/@/language'
  // import { useUniversalFetch } from '/@/universal'
  import { useStores } from '/@/stores'
  import { useEnhancer } from '/@/app/enhancer'
  import { getEtherscanURL, getOpenSeaCollectionURL } from '/@/transforms/nft'
  import { getTargetProxyURL } from '/@/transforms/url'
  import { GAEventCategories } from '/@/constants/gtag'
  import { VALUABLE_LINKS } from '/@/config/app.config'
  import Markdown from '/@/components/common/markdown.vue'
  import PageBanner from '/@/components/common/banner.vue'
  import debounce from 'lodash/debounce'
  import type { OpenSeaAssetItem } from '/@/server/getters/opensea'
  import ZoomIntoView from './zoom-into-view/index.vue'

  export default defineComponent({
    name: 'NftPage',
    components: {
      PageBanner,
      Markdown,
      ZoomIntoView
    },
    setup() {
      const { gtag } = useEnhancer()
      const { openseaAssets, openseaCollections } = useStores()

      const bannerImageUrl = '/img/miHoYo BBS.png'
      const handleGTagEvent = (event: string, label: string) => {
        gtag?.event(event, {
          event_category: GAEventCategories.NFT,
          event_label: label
        })
      }

      let totalAssets: Ref<OpenSeaAssetItem[]> = ref([])
      let isFirstFetching = ref(true)
      let scrollEventHandler
      let scrollContainerElement: HTMLElement | null = null
      let prevScrollTop = 0

      let isOnZoomIntoViewModal = ref<boolean>(false)
      let zoomIntoViewActiveIndex = ref<number>(0)

      const scrollHandler = () => {
        scrollContainerElement = document.querySelector('.desktop-main-wrapper') // 获取滚动容器
        const { scrollTop, scrollHeight, clientHeight } = scrollContainerElement ?? {} // 获取滚动高度和内容高度
        if (scrollTop && scrollHeight && clientHeight) {
          if (
            scrollTop > prevScrollTop &&
            scrollTop + clientHeight >= (scrollHeight - clientHeight) / 2 &&
            !openseaAssets.fetching
          ) {
            loadMore()
          }
          prevScrollTop = scrollTop
        }
      }

      const loadMore = () => {
        if (!openseaAssets.fetching && openseaAssets.data?.next) {
          openseaAssets
            .fetch({
              cursor: openseaAssets.data?.next
            })
            .then(() => {
              totalAssets.value.push(...openseaAssets.assets)
              if (!openseaAssets.data?.next) {
                scrollContainerElement?.removeEventListener('scroll', scrollEventHandler)
              }
            })
        }
      }

      const openZoomIntoViewModal = (imageUrl, imageIndex) => {
        isOnZoomIntoViewModal.value = true
        zoomIntoViewActiveIndex.value = imageIndex
      }

      const closeZoomIntoViewModal = () => {
        isOnZoomIntoViewModal.value = false
      }

      // useUniversalFetch(() => {
      //   return Promise.all([
      //     // eslint-disable-next-line @typescript-eslint/no-empty-function
      //     openseaAssets.fetch().catch(() => {}),
      //     // eslint-disable-next-line @typescript-eslint/no-empty-function
      //     openseaCollections.fetch().catch(() => {})
      //   ])
      // })

      watch(
        () => openseaAssets.fetching,
        (newVal) => {
          if (!newVal) {
            if (isFirstFetching.value) {
              totalAssets.value = openseaAssets.assets || []
              isFirstFetching.value = false

              nextTick(() => {
                scrollContainerElement = document.querySelector('.desktop-main-wrapper')
                if (scrollContainerElement && !scrollEventHandler) {
                  scrollEventHandler = debounce(scrollHandler, 100)
                  scrollContainerElement.addEventListener('scroll', scrollEventHandler, {
                    passive: true
                  })
                }
              })
            }
          }
        }
      )

      onMounted(() => {
        Promise.all([
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          openseaAssets.fetch().catch(() => {}),
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          openseaCollections.fetch().catch(() => {})
        ])
      })

      onUnmounted(() => {
        if (scrollEventHandler) {
          scrollContainerElement?.removeEventListener('scroll', scrollEventHandler)
        }
      })

      return {
        VALUABLE_LINKS,
        LanguageKey,
        bannerImageUrl,
        openseaAssets,
        openseaCollections,
        getTargetProxyURL,
        getEtherscanURL,
        getOpenSeaCollectionURL,
        handleGTagEvent,
        loadMore,
        isFirstFetching,
        totalAssets,

        isOnZoomIntoViewModal,
        zoomIntoViewActiveIndex,
        closeZoomIntoViewModal,
        openZoomIntoViewModal
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .nft-page {
    display: flex;
    // flex-direction: column;
    // height: 100%;
    min-height: $full-page-active-content-height;
    min-width: 1380px;
    overflow: auto;

    .banner {
      position: fixed;
      height: calc(100vh * (1 / var(--scale-factor)) - $header-height - 1rem) !important;
      left: 0;
      top: $header-height + 1rem;
      width: calc(22.7vw * (1 / var(--scale-factor)));
      min-width: 360px;
      z-index: -1;
      ::v-deep(.background) {
        background-color: unset;
        // background-position-x: 38%;
        background-position-x: 34.5%;
      }

      .title {
        font-size: 2em;
      }
    }

    .page-content {
      // width: 77.3vw;
      width: calc(77.3 * (1 / var(--scale-factor)));
      // height: 100%;
      margin-left: calc(22.7vw * (1 / var(--scale-factor)));
      flex-grow: 1;
      // overflow: auto;
      // .asset-list-wrapper {
      //   // height: 100%;
      //   // overflow: auto;
      // }
      &::-webkit-scrollbar {
        width: 0.618rem;
        // background-color: #fff;
      }

      ::v-deep(.container) {
        display: flex;
        flex-direction: column;
        // height: calc(100% - 4.8rem - 4.8rem);
        // height: 100%;
        width: 100%;
      }

      .asset-empty {
        min-height: 28rem;
        font-weight: bold;
      }

      .asset-skeleton,
      .asset-list {
        list-style: none;
        width: 100%;
        margin: 0;
        padding: 0;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        // grid-gap: $gap * 2;

        .asset {
          box-sizing: border-box;
          // height: calc((100vh - 4.8rem - 4.8rem - 60px) / 2);
          height: calc((100vh * (1 / var(--scale-factor)) - $header-height - 60px) / 2);
          margin: 15px 10px;
        }
      }

      .asset-skeleton {
        .item {
          box-sizing: border-box;
          // height: calc((100vh - 4.8rem - 4.8rem - 60px) / 2);
          height: calc((100vh * (1 / var(--scale-factor)) - $header-height - 60px) / 2);
          margin: 15px 10px;
          padding: 1rem;
          @include radius-box($sm-radius);
          @include common-bg-module();

          .image {
            height: 50%;
            margin-bottom: 1em;
          }
        }
      }

      .asset-list {
        .asset {
          position: relative;
          display: block;
          padding: 0 0 $lg-gap;
          // @include radius-box($sm-radius);
          border-radius: 20px;
          user-select: text;
          overflow: hidden;
          @include common-bg-module();

          .image {
            position: relative;
            width: 100%;
            height: 50%;
            overflow: hidden;
            background-color: $white;
            background-size: cover;
            background-position: center;
            cursor: pointer;

            .contract {
              position: absolute;
              top: 1em;
              left: 1em;
              height: 2rem;
              padding: 0 0.5em;
              display: inline-flex;
              align-items: center;
              color: $text-secondary;
              background-color: $module-bg-lighter;
              @include radius-box($sm-radius);
              &:hover {
                color: $link-color;
              }

              .text {
                font-weight: bold;
                font-size: $font-size-small;
                margin-left: $xs-gap;
              }
            }

            .links {
              position: absolute;
              right: 1em;
              bottom: 1em;
              display: flex;

              .item {
                display: inline-block;
                width: 2rem;
                height: 2rem;
                line-height: 2rem;
                text-align: center;
                margin-left: $sm-gap;
                color: $text-secondary;
                background-color: $module-bg-lighter;
                @include radius-box($sm-radius);
                &:hover {
                  color: $link-color;
                }
              }
            }

            .external {
              position: absolute;
              top: 1em;
              right: 1em;
              color: $white;
            }
          }

          .my-content {
            box-sizing: border-box;
            height: 50%;
            padding: $lg-gap;
            overflow: auto;

            .name {
              display: block;
              font-weight: bold;
              font-size: $font-size-h4;
            }

            .collection {
              // display: block;
              display: inline-block;
              width: auto;
              margin-top: $xs-gap;
            }

            .name,
            .collection {
              max-width: 100%;
              @include text-overflow();
              &:hover {
                color: $link-color;
                @include text-underline();
              }
            }

            .description {
              color: $text-secondary;
              font-size: $font-size-small;
              margin-top: $sm-gap;
              margin-bottom: 0;
              line-height: 1.6;
              overflow: auto;
              // @include clamp(5);
              user-select: all;

              ::v-deep(p) {
                line-height: 1.6em;
                margin-bottom: $xs-gap !important;
                &:last-child {
                  margin-bottom: 0 !important;
                }
              }

              ::v-deep(hr) {
                margin: $sm-gap 0 !important;
              }

              section,
              p {
                font-size: $font-size-small;
              }
            }
          }
        }
      }
    }

    .view-more-nft {
      position: fixed;
      left: 20px;
      // margin: 2rem 0;
      margin: 20px 0;
      text-align: center;

      .link {
        display: inline-block;
        padding: 1rem 1em;
        font-weight: bold;
        font-size: $font-size-h5;
        // color: $text-divider;
        color: #888;
        // background-color: $module-bg-darker-2;
        background-color: $white;
        border-radius: 10px;
        @include radius-box($sm-radius);
        &:hover {
          // color: $text-disabled;
          color: $blue;
          // background-color: $module-bg-darker-3;
          background-color: $white;
        }

        .iconfont {
          font-weight: normal;
          margin: 0.2em 0;
        }
      }
    }

    .youtube-modal {
      width: 100%;
      height: 100%;
      position: relative;
      background: $black !important;
    }
  }
</style>
