<template>
  <div id="background">
    <!-- <div class="image" :class="{ dark: isDarkTheme }"></div> -->
    <!-- <transition name="fade" mode="in-out">
      <img
        v-show="!isChangingBackground"
        :src="backgroundImage"
        alt=""
        class="background-image"
        :class="{ dark: isDarkTheme }"
      />
    </transition> -->
    <img
      v-show="!isChangingBackground && !isShowBackgroundImage2"
      :src="backgroundImage"
      alt=""
      class="background-image"
      :class="{ dark: isDarkTheme }"
    />
    <img
      v-show="!isChangingBackground && isShowBackgroundImage2"
      :src="backgroundImage2"
      alt=""
      class="background-image"
      :class="{ dark: isDarkTheme }"
    />
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, computed, watch, nextTick } from 'vue'
  import { getTargetCDNURL } from '/@/transforms/url'
  import { useEnhancer } from '/@/app/enhancer'
  export default defineComponent({
    name: 'Background',
    setup() {
      const { isDarkTheme, globalState, route } = useEnhancer()
      let isChangingBackground = ref(false)
      const isEnabledHeadNav = computed(() => !globalState.layoutColumn.value.isNormal)
      const isShowBackgroundImage2 = computed(() => isEnabledHeadNav.value && !route.meta.isIndex)
      // const backgroundImage = computed(() =>
      //   isEnabledHeadNav.value && !route.meta.isIndex
      //     ? getTargetCDNURL('/images/background-2.webp')
      //     : getTargetCDNURL('/images/background.webp')
      // )
      const backgroundImage = getTargetCDNURL('/images/background.jpg')
      const backgroundImage2 = getTargetCDNURL('/images/background-2.jpg')

      watch(isEnabledHeadNav, (newVal, oldVal) => {
        if (newVal !== oldVal) {
          isChangingBackground.value = true
        }
        nextTick(() => {
          isChangingBackground.value = false
        })
      })

      return {
        isDarkTheme,
        backgroundImage,
        backgroundImage2,
        isEnabledHeadNav,
        isChangingBackground,
        isShowBackgroundImage2
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  .fade-enter-to,
  .fade-leave-from {
    opacity: 1;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 1s ease;
  }

  #background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    // z-index: $z-index-underground;
    z-index: -2;
    background-color: $body-bg;
    .background-image {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0.2;
      // background: url('/images/background.png') 100% / cover;
      object-fit: cover;
      &.dark {
        opacity: 0.2;
      }
    }
  }
</style>
