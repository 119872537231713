export const ZH_SAYING = Object.freeze([
  {
    text: '觉宇宙之无穷，识盈虚之有数。',
    author: '王勃',
    source: '《滕王阁序》',
    language: 'zh'
  },
  {
    text: '不以物喜，不以己悲。',
    author: '范仲淹',
    source: '《岳阳楼记》',
    language: 'zh'
  },
  {
    text: '出淤泥而不染，濯清涟而不妖。',
    author: '周敦颐',
    source: '《爱莲说》',
    language: 'zh'
  },
  {
    text: '莫听穿林打叶声，一蓑烟雨任平生。',
    author: '苏轼',
    source: '《定风波·莫听穿林打叶声》',
    language: 'zh'
  },
  {
    text: '非淡泊无以明志，非宁静无以致远。',
    author: '诸葛亮',
    source: '《诫子书》',
    language: 'zh'
  },
  {
    text: '青山依旧在，几度夕阳红。',
    author: '杨慎 ',
    source: '《临江仙·滚滚长江东逝水》',
    language: 'zh'
  },
  {
    text: '昨天，我做了一个梦。',
    author: '新海诚',
    source: '《秒速五厘米》',
    language: 'zh'
  },
  {
    text: '不，已经入春了。',
    author: '米泽惠信',
    source: '《冰菓》',
    language: 'zh'
  },
  {
    text: '所以温柔是谎言。',
    author: '渡航',
    source: '《春物》',
    language: 'zh'
  },
  {
    text: '迷失的人迷失了，重逢的人会再重逢。',
    author: '宫本雅俊',
    source: '《村上春树·旅》',
    language: 'zh'
  },
  {
    text: '不存在十全十美的文章，如同不存在彻头彻尾的绝望。',
    author: '村上春树',
    source: '《且听风吟》',
    language: 'zh'
  },
  {
    text: '哪里会有人喜欢孤独，不过是不喜欢失望。',
    author: '村上春树',
    source: '《挪威的森林》',
    language: 'zh'
  },
  {
    text: '眼前的风景已和从前大不一样了。',
    author: '村上春树',
    source: '《1Q84》',
    language: 'zh'
  },
  {
    text: '人是一瞬间变老的。',
    author: '村上春树',
    source: '《舞！舞！舞！》',
    language: 'zh'
  },
  {
    text: '远看，大部分事物都很美丽。',
    author: '村上春树',
    source: '《刺杀骑士团长》',
    language: 'zh'
  },
  {
    text: '没关系的，都一样。',
    author: '刘慈欣',
    source: '《三体》',
    language: 'zh'
  }
])

export const EN_SAYING = Object.freeze([
  {
    text: 'Was mich nicht umbringt, macht mich stärker.',
    author: 'Nietzsche',
    source: '《Flachland》',
    language: 'en'
  },
  {
    text: 'Why so serious?',
    author: 'The Joker',
    source: '《The Dark Knight》',
    language: 'en'
  },
  {
    text: 'I believe… whatever doesn’t kill you simply makes you… stranger.',
    author: 'The Joker',
    source: '《The Dark Knight》',
    language: 'en'
  },
  {
    text: 'Things always get worse before they get better.',
    author: 'The Joker',
    source: '《The Dark Knight》',
    language: 'en'
  },
  {
    text: 'You see, madness, as you know, is like gravity. All it takes is a little push!',
    author: 'The Joker',
    source: '《The Dark Knight》',
    language: 'en'
  }
])
