<template>
  <div ref="refOfswiperContainer" id="desktop-layout" class="desktop-layout">
    <header-view />
    <div class="main-view">
      <swiper
        ref="refOfIndexSwiper"
        direction="vertical"
        :initial-slide="initialSlide"
        :speed="300"
        :parallax="false"
        :slides-per-view="1"
        :set-wrapper-size="true"
        :mousewheel="true"
        :observe-parents="true"
        :grab-cursor="false"
        :simulate-touch="false"
        :preload-images="true"
        :lazy="true"
        @swiper="handleSwiperReady"
      >
        <swiper-slide>
          <div v-if="route.meta.isIndex" class="intro-wrapper">
            <intro-page ref="refOfIntro" />
            <!-- <router-view name="intro"></router-view> -->
          </div>
        </swiper-slide>
        <swiper-slide>
          <div id="desktop-main-wrapper" class="desktop-main-wrapper">
            <desktop-main />
            <footer-view class="footer-view" />
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, nextTick, watch, onBeforeUnmount, provide, watchEffect } from 'vue'
  import { useEnhancer } from '/@/app/enhancer'
  import SwiperClass, { Swiper, SwiperSlide } from '/@/effects/swiper'
  import { useBackgroundVideo } from '/@/components/widget/background-video/background-video'
  import { useTabVisibilityHook } from '/@/composables/tab-visibility'
  import { RouteName } from '/@/app/router'
  import IntroPage from '/@/pages/index/intro.vue'
  import DesktopMain from '/@/components/layout/desktop/main.vue'
  import HeaderView from '/@/components/layout/desktop/header.vue'
  import FooterView from '/@/components/layout/desktop/footer.vue'
  import debounce from 'lodash/debounce'

  export default defineComponent({
    name: 'IndexPage',
    components: {
      Swiper,
      SwiperSlide,
      HeaderView,
      FooterView,
      IntroPage,
      DesktopMain
    },
    setup() {
      const { route, router } = useEnhancer()

      useTabVisibilityHook()

      const refOfIntro: any = ref(null)
      const refOfIndexSwiper: any = ref(null)
      let initialSlide: any = ref(0)
      let indexSwiper = ref<SwiperClass | null>(null)
      let indexBackgroundVideoRef: any = ref(null)
      let { playVideoPlayer, pauseVideoPlayer } = useBackgroundVideo({
        ctxStateVideoPlayer: {
          player: indexBackgroundVideoRef.value
        }
      })

      watchEffect(() => {
        indexBackgroundVideoRef.value = refOfIntro.value?.$refs.refOfBackgroundVideo.$refs.videoRef
        // 调用 useBackgroundVideo 更新 playVideoPlayer 和 pauseVideoPlayer
        const { playVideoPlayer: newPlayVideoPlayer, pauseVideoPlayer: newPauseVideoPlayer } =
          useBackgroundVideo({
            ctxStateVideoPlayer: {
              player: indexBackgroundVideoRef.value
            }
          })

        // 更新 playVideoPlayer 和 pauseVideoPlayer
        playVideoPlayer = newPlayVideoPlayer
        pauseVideoPlayer = newPauseVideoPlayer
      })

      const wheelHandler = (_swiper) => {
        return function (event) {
          // indexSwiper.value = _swiper
          const scrollWrapper = document.getElementById('desktop-main-wrapper')
          const deltaY = event.deltaY
          const wheelNodeList = ['.nav-list', '.carrousel', '.mammon']
          const isInWheelNode = wheelNodeList.some((item) => {
            return event.target.closest(item)
          })
          const maskElement = document.querySelector('.mask')
          const isInMaskNode = !(
            maskElement && getComputedStyle(maskElement).getPropertyValue('display') === 'none'
          )
          // event.target.closest('.nav-list')
          indexBackgroundVideoRef.value =
            refOfIntro.value?.$refs.refOfBackgroundVideo.$refs.videoRef
          if (deltaY > 0 && _swiper.activeIndex === 1 && route.meta.isIndex) {
            if (route.name === RouteName.Index) {
              pauseVideoPlayer()
              _swiper.slideNext()
              _swiper.disable()
              // if (route.name === 'index') {
              router.push({ name: RouteName.Home })
              // }
            }
          }
          if (
            deltaY < 0 && // 是否为向上滚动
            scrollWrapper?.scrollTop === 0 && // 是否已滚动到顶部
            route.meta.isIndex && // 是否为首页组件路由
            !isInWheelNode && // 鼠标target是否在其他滚动节点内
            !isInMaskNode // 鼠标target是否在mask节点内
          ) {
            if (route.name === RouteName.Home) {
              _swiper.enable()
              _swiper.slidePrev()
              router.push({ name: RouteName.Index })
              // refOfIntro.value?.$refs.refOfBackgroundVideo.$refs.videoRef.play()
              playVideoPlayer()
            }
          }
        }
      }
      let swiperEventHandler
      const handleSwiperReady = (_swiper: SwiperClass) => {
        indexSwiper.value = _swiper
        if (!swiperEventHandler && route.meta.isIndex) {
          swiperEventHandler = debounce(wheelHandler(_swiper), 50)
          window.addEventListener('wheel', swiperEventHandler, { passive: true })
        }
      }

      watch(
        () => route,
        (newVal) => {
          indexSwiper.value = refOfIndexSwiper?.value?.$el?.swiper
          if (!newVal.meta.isIndex) {
            indexSwiper.value?.enable()
            initialSlide.value = 1
            indexSwiper.value?.slideTo(1)
            if (swiperEventHandler) {
              window.removeEventListener('wheel', swiperEventHandler)
            }
            swiperEventHandler = null
            nextTick(() => {
              indexSwiper.value?.disable()
            })
          } else if (newVal.name !== RouteName.Index) {
            pauseVideoPlayer()
            initialSlide.value = 1
            indexSwiper.value?.slideTo(1)
            if ((newVal.meta.from as any)?.name !== RouteName.Index) {
              nextTick(() => {
                if (indexSwiper.value) {
                  indexSwiper.value?.disable()
                  handleSwiperReady(indexSwiper.value)
                }
              })
            }
          } else {
            indexSwiper.value?.enable()
            initialSlide.value = 0
            indexSwiper.value?.slideTo(0)
            if (indexSwiper.value) {
              handleSwiperReady(indexSwiper.value)
            }
          }
        },
        {
          deep: true,
          immediate: true
        }
      )

      onBeforeUnmount(() => {
        window.removeEventListener('wheel', swiperEventHandler)
        swiperEventHandler = null
      })

      provide('provideIndexPage', {
        indexSwiper,
        indexBackgroundVideoRef
      })

      return {
        swiperEventHandler,
        handleSwiperReady,
        refOfIndexSwiper,
        refOfIntro,
        initialSlide,
        route
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .desktop-layout {
    // width: 100%;
    // height: 100%;
    @include responsive-scale-factor;
    // // zoom: var(--scale-factor);
    // transform: scale(var(--scale-factor));
    transform: translateZ(0) scale(var(--scale-factor), var(--scale-factor));
    transform-origin: top left;
    width: calc(100vw * (1 / var(--scale-factor)));
    height: calc(100vh * (1 / var(--scale-factor)));
    overflow: hidden;
    will-change: transform;
    -webkit-font-smoothing: antialiased;
  }

  .main-view {
    // min-height: calc(100vh - $header-height);
    // height: calc(100vh - $header-height);
    height: 100%;
  }

  .swiper {
    // top: $header-height;
    // height: calc(100vh - $header-height);
    height: 100%;
  }

  .swiper-slide {
    min-height: 100vh;
    overflow: hidden;
  }

  .intro-wrapper {
    width: 100%;
    height: calc(100% - $header-height);
    margin-top: $header-height;
  }

  .desktop-main-wrapper {
    width: 100%;
    height: 100%;
    // height: calc(100vh - $header-height);
    // margin-top: -4.8rem;
    // padding-top: $header-height;
    // padding-top: $header-height + $lg-gap;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0.618rem;
      // background-color: #fff;
    }
  }

  .mark-text {
    position: fixed;
    top: 6rem;
    background: red;
  }

  .footer-view {
    margin-top: $lg-gap;
    .container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
</style>
