<template>
  <div class="background-container">
    <!-- <div v-if="!isChanging"> -->
    <div>
      <video ref="videoRef" :id="videoId" class="background-video">
        <source :src="videoSrc" :type="videoType" />
      </video>
    </div>
    <img v-show="isLoading" :src="imageSrc" alt="" class="background-image" />
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'BackgroundVideo',
    props: {
      isChanging: {
        type: Boolean,
        required: false,
        default: false
      },
      isLoading: {
        type: Boolean,
        required: false,
        default: true
      },
      videoId: {
        type: String,
        required: true
      },
      videoSrc: {
        type: String,
        required: true
      },
      videoType: {
        type: String,
        required: false,
        default: 'application/x-mpegURL'
      },
      imageSrc: {
        type: String,
        required: false
      }
    },
    setup() {
      return {}
    }
  })
</script>

<style lang="scss" scoped>
  @import 'src/styles/variables.scss';
  @import 'src/styles/mixins.scss';

  .pip-video {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 9999;
  }

  .background-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 88%;
    min-width: 1920px;
    min-height: 720px;
    max-height: 1043px;
    overflow: hidden;
    object-position: center;

    // 已改用filter: brightness(0.5)实现;
    // &::after {
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   background-color: rgba($black, 0.3);
    // }
    // > div {
    //   position: relative;
    //   height: 100%;
    // }
  }
  .background-video {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-width: 1920px;
    min-height: 720px;
    overflow: hidden;
    // // object-fit: cover;
    // // object-position: 0% 30%;
    object-position: center;
    // // pointer-events: none;

    .vjs-tech {
      width: 100%;
      // height: 100%;
      filter: brightness(0.8);
    }
  }
  .background-image {
    position: absolute;
    left: 0;
    width: 100%;
    filter: brightness(0.8);
  }
</style>
