/**
 * @file SSR BFF API Tunnel
 * @module service.tunnel
 * @author rosygray <https://github.com/rosygray>
 */

import axios, { AxiosInstance } from 'axios'
import { BFF_TUNNEL_PREFIX, getBFFServerPort } from '/@/config/bff.config'
import { TunnelModule } from '/@/constants/tunnel'
import { isClient } from '/@/app/environment'

const tunnel = axios.create({
  // baseURL: isClient
  //   ? `https://blog.rosygray.com${BFF_TUNNEL_PREFIX}`
  //   : `http://localhost:${getBFFServerPort()}${BFF_TUNNEL_PREFIX}`
  baseURL: isClient
    ? BFF_TUNNEL_PREFIX
    : `http://localhost:${getBFFServerPort()}${BFF_TUNNEL_PREFIX}`
  // baseURL: `http://localhost:${getBFFServerPort()}${BFF_TUNNEL_PREFIX}`
  // baseURL: `http://localhost:10082${BFF_TUNNEL_PREFIX}`
  // baseURL: `http://127.0.0.1:10082${BFF_TUNNEL_PREFIX}`
  // baseURL: `http://172.24.39.107:10082${BFF_TUNNEL_PREFIX}`
})

tunnel.interceptors.response.use((response) => response.data)

export default {
  $: tunnel,
  request: <T = any>(...args: Parameters<AxiosInstance['request']>): Promise<T> => {
    return tunnel.request(...args)
  },
  dispatch: <T = any>(module: TunnelModule, params?: any): Promise<T> => {
    return tunnel.get(module, { params })
  }
}
