/**
 * @file Proxy constant
 * @module constant.proxy
 * @author rosygray <https://github.com/rosygray>
 */

export enum ProxyModule {
  Default = 'default',
  Douban = 'douban',
  NetEaseMusic = 'netease-music',
  Instagram = 'instagram',
  YouTube = 'youtube',
  Disqus = 'disqus'
}
